import React, { useEffect, useState } from 'react';
import "../../Styles/BuyPackages.css";
import arrowimg from "../../Assets/arrowimg.png";
import altarrow from "../../Assets/altarrow.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { BASE_URL } from "../../Services/BaseUrl";
import { useNavigate } from 'react-router-dom';
import RenderRazorpay from '../../Components/PayModule/PayModule';

const BuyPackages = () => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [gst,setGst]=useState([])
  const [totalAmount, setTotalAmount] = useState(0);
  const [packagesTypes, setPackagesTypes] = useState([]);
  const [packagesData, setPackagesData] = useState({});
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [existingPackages, setExistingPackages] = useState([]);
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const navigate = useNavigate();

  const handleCheckboxChange = (cardIndex, option) => {
    if (selectedItem === option) {
      setSelectedItem(null);
      setSelectedCardIndex(null);
      setTotalAmount(0);
    } else {
      setSelectedItem(option);
      setSelectedCardIndex(cardIndex);
      setTotalAmount(option.packagePrice);
    }
  };

  const fetchPackageTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/PackageTypes`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setPackagesTypes(response.data);
      response.data.forEach((packageType) => {
        fetchPackages(packageType.packageTypeId);
      });
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const fetchGst = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Gst`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setGst(response.data);
      console.log(response.data,"gstt")
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };


  const fetchPackages = async (packageTypeId) => {
    try {
      const response = await axios.get(`${BASE_URL}/Packages/${packageTypeId}/packagetypes`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setPackagesData((prevData) => ({
        ...prevData,
        [packageTypeId]: response.data,
      }));
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const fetchExistingPackages = async () => {
    try {
      console.log(userId, "userId")
      const response = await axios.get(`${BASE_URL}/Packages/${userId}/postingads`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      console.log(response.data,'exst res')
      setExistingPackages(response.data);
    } catch (error) {
      console.error("Error fetching existing packages:", error);
      // setExistingPackages([]); // Default to an empty array in case of error


    }
  };



  useEffect(() => {
    fetchGst()
    fetchPackageTypes();
    fetchExistingPackages();
  }, []);

  // Check existing packages on update
  // useEffect(() => {
  //   if (existingPackages.length > 0 && Object.keys(packagesData).length > 0) {
  //     const matchedPackage = existingPackages.find(existing =>
  //       Object.values(packagesData).flat().some(pkg => pkg.packagesId === existing.packagesId && existing.isPackageEnable)
  //     );
  //     console.log(matchedPackage,"matched packages")

  //     if (matchedPackage) {
  //       const packageToSelect = Object.values(packagesData).flat().find(pkg => pkg.packagesId === matchedPackage.packagesId);
  //       if (packageToSelect) {
  //         setSelectedItem(packageToSelect);
  //         setSelectedCardIndex(packagesData[packageToSelect.packageTypeId].findIndex(pkg => pkg.packagesId === packageToSelect.packagesId));
  //         setTotalAmount(packageToSelect.packagePrice);
  //       }
  //     }
  //   }
  // }, [existingPackages, packagesData]);

  const handleSubmitPackages = async ({ orderDetails }) => {
    if (!selectedItem) {
      toast.warn("Please select a package before proceeding.");
      return;
    }
    console.log(selectedItem, "dhf");
    const advertiser = {
      userId: Number(userId),
      packagesId: selectedItem.packagesId,
      payorderId: orderDetails,
      paymentPrice: String(selectedItem.packagePrice),
      paymentDone: true,
      paymentType: "string",
      noOfAdsUsed: 0,
      packageEndDate: "2024-11-02T05:24:14.970Z",
      createdDate: "2024-11-02T05:24:14.970Z",
      invoiceNumber: "string"
    };
    console.log(JSON.stringify(advertiser), "advertiser");
    try {
      const response = await axios.post(`${BASE_URL}/Advertisers`, advertiser,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      console.log(response, "advertiser created successfully");
      setExistingPackages([{ packagesId: selectedItem.packagesId, isPackageEnable: true }]); 
      toast.success("Payment successful");
      setSelectedItem(null);
      setSelectedCardIndex(null);
      setTotalAmount(0);
    } catch (error) {
      console.error("Error submitting payment:", error);
    }
  };

  const createPayment = () => {
    if (!userId) {
      toast.error("Please login to proceed with payment.");
      return;
    }
  console.log(selectedItem,'sel it')
    console.log("Control entered here");
    console.log(existingPackages, 'in createPayment');
    console.log(existingPackages.isPackageEnable, 'in createPayment');
    if (existingPackages) {
      toast.warn("An active package already exists.");
      setSelectedItem(null);

    } else {
      setDisplayRazorpay(true);
    }
  };
  
  

  const handlePayment = (status, orderDetails) => {
    console.log(status)
    if (status === "succeeded") {

      setDisplayRazorpay(false);
      handleSubmitPackages(orderDetails);
      setSelectedItem(null);
      setSelectedCardIndex(null);
    setTotalAmount(0);

    } else if (status === "cancelled") {
      setDisplayRazorpay(false);
    }

  };

  const calculateTotalWithGST = () => {
    if (gst.length > 0 && totalAmount > 0) {
      const gstPercentage = gst[0]?.gstName || 0; // Ensure GST value is fetched
      return totalAmount + (totalAmount * gstPercentage) / 100;
    }
    return totalAmount;
  };


  const apiKey = 'rzp_test_4rrCmYtqWUOUvT';
  console.log(typeof existingPackages, "existing packages");

  return (
    <div className="bp-buy-packages">
      <ToastContainer />
      <div className="bp-row">
        <div className="bp-row1" onClick={() => navigate('/home')}>
          <img src={arrowimg} loading='lazy' alt="arrow" className="bp-img1" />
          <p className="bp-p1">Choose Package</p>
        </div>
        <div className="bp-card-container">
          {packagesTypes.map((card, cardIndex) => (
            <div className="bp-card" key={card.packageTypeId}>
              <div className="bp-card-header">
                <p className="bp-first">{card.packageTypeName}</p>
                <p className="bp-p2">{card.description}</p>
              </div>
              <div className='bp-card-cont'>
              {packagesData[card.packageTypeId]?.map((option) => {
  // Check if the package exists in existingPackages and if it’s enabled
  const isChecked = selectedItem?.packagesId === option.packagesId || 
                    (existingPackages[option.packagesId] && existingPackages[option.packagesId].isPackageEnable);

  return (
    <div className="bp-option" key={option.packagesId} onClick={() => handleCheckboxChange(cardIndex, option)}>
      <input
        type="checkbox"
        id={option.packagesId}
        checked={isChecked}
        onChange={() => handleCheckboxChange(cardIndex, option)}
      />
      <span>{`₹ ${option.packagePrice} / ${option.noOfAdds} ADS / ${option.planValidity}`}</span>
    </div>
  );
})}

              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedItem && (
        <div className="bp-final">
          <div className="bp-final1">
          <p className="bp-amount">
  {`1 Item(s) | Total - ₹ ${totalAmount} | GST (${gst[0]?.gstName}%) - ₹ ${((totalAmount * gst[0]?.gstName) / 100).toFixed(2)} | Grand Total - ₹ ${calculateTotalWithGST().toFixed(2)}`}
</p>

            <button className="bp-toast" onClick={createPayment}>
              Proceed to Checkout
            </button>
          </div>
        </div>
      )}

      {displayRazorpay && (
        <RenderRazorpay
        amount={calculateTotalWithGST() * 100} 
          currency={"INR"}
          keyId={apiKey}
          handlePayment={handlePayment}
          name={localStorage.getItem('username')}
        />
      )}
    </div>
  );
};

export default BuyPackages;
