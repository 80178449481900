import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Services/BaseUrl';
import { useNavigate } from 'react-router-dom';


const CategoriesPage = () => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const token=localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');
    const radius = localStorage.getItem('radius');

    const encodeUri = (val) => {
        if (val && typeof val === 'string') {
            if (val.startsWith("http")) return val;
            return `${BASE_URL}/Upload/${encodeURI(val)}`;
        }
        console.warn("Provided value is null or undefined, returning empty string.");
        return "";
    };


    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/Categories`,{
              headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
              },
            });
            setCategories(response.data);
            console.log(response.data,"categoriessss.........");
        } catch (error) {
            console.error('Error fetching categories', error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

  return (
    <div className='servicecategory'>
          <div className="newProperty-title">Services</div>
    <div className='categoryservice-container'>
  
    {categories.slice(1,-1).map((category, index) => (
        <div
            className="categories-card"
            key={index}
            onClick={() => navigate(`/category/${category.categoryId}`, { state: { catname: category.categoryName } })}
            >
            <div className="categories-image">
                <img src={encodeUri(category.icon)} loading="lazy" alt={category.categoryName} style={{ width: '50px' }} />
            </div>
            <div className="categories-card-content">
                <span className="categories-title">{category.categoryName}</span>
            </div>
        </div>
    ))}
    </div>

</div>
  )
}

export default CategoriesPage