import React, { useEffect, useState } from 'react';
import "../../Styles/Editprofile.css";
import profileimg from "../../Assets/no images.png";
import calimg from "../../Assets/calimg.png";
import groupimg from "../../Assets/groupimg.png";
import edit from "../../Assets/edit.png";
import { MdDelete } from 'react-icons/md';
import axios from "axios";
import { BASE_URL } from "../../Services/BaseUrl";
import { MdPhotoSizeSelectActual } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Editprofile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState("");
  const [profileImage, setProfileImage] = useState(localStorage.getItem('profileImage') || profileimg);
  const [newImageUrl, setNewImageUrl] = useState('');
  const [defaultImage, setDefaultImage] = useState('');
  const [profile, setProfile] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [userName, setUserName] = useState("");
  const userId = localStorage.getItem('userId');
  const token=localStorage.getItem('token');
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');
  const mobileNumber = localStorage.getItem('mobileNumber');
  const navigate = useNavigate();
  
  const encodeUri = (val) => {
    if (!val) 
      return profileimg;
    return val.startsWith("http") ? val : `${BASE_URL}/Upload/${encodeURI(val)}`;
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setProfileImage(imageUrl);
      setImageFile(file);
      updateImage(file); // Call updateImage to upload and set new image URL
    }
  };

  const handleInputChange = (e) => {
    setName(e.target.value);
  };

  const fetchProfile = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Users/${userId}`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setProfile(response.data);
      setName(response.data.username);
      const imageUrl = encodeUri(response.data.profileImage);
      setProfileImage(imageUrl);
      localStorage.setItem('profileImage', imageUrl);
      setIsEditing(false);
    } catch (error) {
      console.error('Error fetching profile', error);
    }
  };

  const updateImage = async (imgFile) => {
    let formData = new FormData();
    formData.append("postedFile", imgFile);

    try {
      const response = await axios.post(`${BASE_URL}/Upload`, formData,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      const uploadedImageUrl = response.data.filePath;
      setNewImageUrl(uploadedImageUrl); // Set new image URL in state
      const imageUrl = encodeUri(uploadedImageUrl);
      setProfileImage(imageUrl); // Update component state
      localStorage.setItem('profileImage', imageUrl); // Update local storage
    } catch (error) {
      console.error('Error updating profile image:', error.response?.data || error.message);
    }
  };

  const updateProfile = async () => {
    console.log(profile.profileImage,'ine',profileImage)
    
    const updatedImageUrl = newImageUrl || defaultImage;
    const updatedUsername = name || profile.username;

    const details = {
      userId: userId,
      username: updatedUsername,
      profileImage: updatedImageUrl,
      mobileNumber: mobileNumber,
    };

    try {
      await axios.post(`${BASE_URL}/Users/${userId}`, details,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      if (updatedImageUrl) {
        const imageUrl = encodeUri(updatedImageUrl);
        localStorage.setItem('profileImage', imageUrl); // Ensure localStorage is updated with latest image URL
      }
      localStorage.setItem('username', updatedUsername);
      window.dispatchEvent(new Event("storage")); // Refresh Navbar if needed
      fetchProfile(); // Fetch updated profile
    } catch (error) {
      console.error('Error updating profile:', error.response?.data || error.message);
    }
  };

  const handleDeleteImage = () => {
    setProfileImage(profileimg);
    setDefaultImage(encodeUri(profileimg))
    localStorage.removeItem("profileImage");
  };

  useEffect(() => {
    fetchProfile();
  }, []);
  console.log(defaultImage,'def in del')

  return (
    <div className="ep-container">
      <div className="ep-flex-wrapper">
        <div className="ep-profile-card">
          <div className="ep-group">
            {isEditing ? (
              <div className="ep-edit-mode">
                <input
                  type="text"
                  value={name}
                  placeholder="Enter Your Name"
                  onChange={handleInputChange}
                  className="ep-name-input"
                />
                <p>{name}</p>
              </div>
            ) : profile ? (
              <div>
                <h3 className="ep-h3">{profile.username}</h3>
                <div className="ep-group1">
                  <img src={calimg} alt="Calendar Icon" loading="lazy" className="ep-calimg" />
                  <p className="ep-mem">
                    {profile.createdDate
                      ? new Date(profile.createdDate).toLocaleDateString()
                      : new Date().toLocaleDateString()}
                  </p>
                </div>
              </div>
            ) : (
              <p>Loading profile...</p>
            )}
          </div>
  
          <div className="ep-profile-image-wrapper">
            <img src={profileImage} alt="Profile" loading="lazy" className="ep-profile-image" />
            {isEditing && (
              <div className="ep-upload-delete-wrapper">
                <div className="ep-upload-overlay">
                  <input
                    type="file"
                    id="file-upload"
                    onChange={handleFileChange}
                    className="ep-file-input"
                  />
                  <label htmlFor="file-upload">
                    <MdPhotoSizeSelectActual className="ep-upload-icon" />
                  </label>
                </div>
                <div className="ep-upload-overlay" onClick={handleDeleteImage}>
                  <MdDelete className="ep-upload-icon" />
                </div>
              </div>
            )}
          </div>
  
          {isEditing ? (
            <button
              className="ep-edit"
              onClick={() => {
                setIsEditing(false);
                updateProfile();
              }}
            >
              <img src={edit} alt="Edit Icon" loading="lazy" className="ep-editimg" />
              <div className="ep-p1">Save</div>
            </button>
          ) : (
            <button className="ep-edit" onClick={() => setIsEditing(true)}>
              <img src={edit} alt="Edit Icon" loading="lazy" className="ep-editimg" />
              <div className="ep-p1">Edit Profile</div>
            </button>
          )}
        </div>
  
        <div className="ep-empty-state">
          <img src={groupimg} alt="Group" loading="lazy" className="ep-empty-image" />
          <h2 className="ep-h2">You haven’t listed anything</h2>
          <p className="ep-start-listing-button" onClick={() => navigate("/list-property")}>
            Start Listing
          </p>
        </div>
      </div>
    </div>
  );
};

export default Editprofile;
