import React from 'react';
import PropertyReq from '../../Components/HomeComponent/PropertyReq';
import { BASE_URL } from '../../Services/BaseUrl';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const NewProperty = ({ data, loading }) => {
  const navigate = useNavigate();

  const encodeUri = (val) => {
    // Check if val is not null or undefined
    if (val && typeof val === 'string') {
      // Check if the value starts with 'http'
      if (val.startsWith("http")) {
        return val; // Return the URL as is
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`; // Encode and return
    }
    
    // Handle the case where val is null or undefined
    console.warn("Provided value is null or undefined, returning empty string.");
    return ""; // Or handle it as you see fit
  };
  

  const handlePropertyClick = (propertyId) => {
    console.log("Navigating to property with ID:", propertyId);
    const params = new URLSearchParams({ propertyTypeId: propertyId });
    navigate(`/properties/${propertyId}`);
  };

  console.log(data,"data")


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1440, // Between 1024px and 1440px
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
        },
      }, 
      {
        breakpoint: 1300, // Between 1024px and 1440px
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
        },
      },  
      {
        breakpoint: 1162, // Between 1024px and 1440px
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      }, 
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };
console.log(data ,'from prop page')
  return (
    <div className='NewProperty-container'>
      <div className='component-title'>
        <p className='newlyListed'>Newly Listed</p>
        <p className='newProperty-title'>Search By Property Requirement</p>
      </div>

      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className='slider-container'>
            <Slider {...settings}>
          {data && Array.isArray(data) && data.map((property) => (
            <PropertyReq
              key={property.propertyTypeId}
              image={decodeImgeString(property.propertyImage)}
              title={property.propertyTypeName}
              price={property.propertyCount}
              onClick={() => handlePropertyClick(property.propertyTypeId)} // Pass the click handler
            />
          ))}
            </Slider>
        </div>
      )}
    </div>
  );
};

export default NewProperty;
