import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BsThreeDotsVertical } from "react-icons/bs";
// import PromotionBanner from "../chat/PromotionBanner";
// import { BsThreeDotsVertical } from "react-icons/bs";
import "../../Styles/Service.css";
import { BASE_URL } from "../../Services/BaseUrl";
// import servicedp from "../../Assets/Service-dp.svg";
import eye from "../../Assets/eye icon.svg";
import heart from "../../Assets/heart.svg";
import sellinglady from '../../Assets/sellinglady.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Jobs = () => {
    const [Jobs,setJobs]=useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    const [popupVisible, setPopupVisible] = useState(null); 
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');
    const radius = localStorage.getItem('radius');


    const handlePopupToggle = (index) => {
      setPopupVisible(popupVisible === index ? null : index); // Toggle popup visibility
    };

    const encodeUri = (val) => {
        if (val && typeof val === "string") {
          if (val.startsWith("http")) {
            return val;
          }
          return `${BASE_URL}/Upload/${encodeURI(val)}`;
        }
        return "";
      };
    
      const decodeImgeString = (str) => {
        if (str && str.split(",").length > 1) {
          const imageUrl = str.split(",")[0];
          return encodeUri(imageUrl);
        } else if (str) {
          return encodeUri(str);
        }
      };


      const handleAddJobs = () => {
        navigate("/jobs-form");
      };
        
    const fetchJobs = async () => {
        console.log('Fetching services')
        setLoading(true);
        try {
          const response = await axios.get(`${BASE_URL}/Jobs/${userId}/user`,{
            params: {
              userId: userId,
            },
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          });
          if (response.status !== 204) {
            setJobs(response.data);
            console.log(response.data,"jobss...........")
            // setAdvertisement(response.data.ad);
          } else {
            setJobs([]);
            // setAdvertisement([]);
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };

      useEffect(() => {
        fetchJobs();
      }, [userId]);


      
  const handleDeleteService = async (jobsId) => {
    console.log(jobsId,"servicesId response");
    try {
      const response = await axios.post(`${BASE_URL}/Jobs/${jobsId}/delete`,{},{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      console.log(response, 'del res');
      toast.success('Service deleted successfully');
      setTimeout(()=>{
        fetchJobs();
      },
    300)
     
      // Refetch the services list after deletion
      // fetchServices();
    } catch (err) {
      console.error(err);
      toast.error('Failed to delete service');
    }
  };

  return (
    <div className="serv-cont">
    <ToastContainer />
    <div className="serv-left-cont">
      <div className="serv-breadcrumb-line">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/home">Home</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <a href="/my-services">Service</a>
            </li>
          </ol>
        </nav>
        <button className="btn btn-primary" onClick={handleAddJobs}>
          Add Jobs
        </button>
      </div>
      <div>
        {Jobs && Jobs.length > 0 ? (
          <div className="serv-cards">
            {Jobs.map((job, index) => (
              <div key={index} className="serv-card-full-cont">
                <div className="serv-card" onClick={() => navigate(`/my-jobs/${job.jobsId}`)}>
                  <img src={decodeImgeString(job.images)} loading="lazy" alt={job.adTitle} />
                  <div className="serv-details">
                    <div className="serv-title">
                      <p className="primary-text-dark">{job.jobRoleName}</p>
                      
                      <div style={{ position: 'relative', display: 'inline-block' }}>
                        <BsThreeDotsVertical
                          className="three-dots"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent triggering the card click
                            handlePopupToggle(index);
                          }}
                        />
                        {popupVisible === index && (
                          <div className="popup-menu">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDeleteService(job.jobsId);
                                setPopupVisible(null);
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <p className="secondary-text" style={{ color: "#00DBBF" }}>
                      {job.categoryName}
                    </p> */}
                    <p className="secondary-text" style={{ color: "#00DBBF" }}>
                      {job.companyName}
                    </p>
                    <p className="job-salary">₹{job.salaryFrom}-₹{job.salaryTo}</p>
                    <div className="serv-stats">
                      {/* <div>
                        <img src={eye} loading="lazy" alt="Views" />
                        {job.companyName}
                      </div> */}
                      {/* <div>
                        <img src={heart} loading="lazy" alt="Likes" />
                        {job.jobRoleName}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <img src={sellinglady} loading="lazy" alt="" style={{ height: "35%", width: "45%" }} />
          </div>
        )}
      </div>
    </div>
    <div>
      {/* {advertisement ? (
        <div className="serv-right-cont">
          {advertisement.map((add, index) => (
            <div key={index} className="advertisement">
              <img src={encodeUri(add.imageUrl)} loading="lazy" alt="Advertisement" className="advertisement" />
            </div>
          ))}
        </div>
      ) : (
        <p>empty</p>
      )} */}
    </div>
  </div>
  )
}

export default Jobs