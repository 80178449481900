import React from "react"
import Navbar from "./navbar"
import Footer from "./footer"


export default function AppLayout(props) {
    return (
        <>
            <Navbar/>
            {props.children}
            <Footer/>
        </>
    )
}