import React, { useState, useEffect } from "react";
import "../../Styles/ServiceForm.css";
import { useNavigate, useParams } from "react-router-dom";
import partyicon from "../../Assets/party-icon.svg";
import PaymentScreen from "./Paymentscreen";
import { FaRegImage } from "react-icons/fa6";
import { BASE_URL } from "../../Services/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import { MAP_API } from "../../Services/GmapAPI";
import { FaCaretDown, FaCircleQuestion} from "react-icons/fa6";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api"; 
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RenderRazorpay from '../../Components/PayModule/PayModule';
import service from '../../Assets/services.png'
import circle from '../../Assets/service-round.png'

const EditService = () => {
    const navigate = useNavigate(); // Hook to navigate between routes
    const {servicesId}=useParams();
    const [serviceplans, setServiceplans] = useState([]);
    // const [selectedImage, setSelectedImage] = useState(null);
    const [categories, setCategories] = useState([]);
    const[uploadProgress ,setUploadProgress] = useState(0)
    const [Subcategories, setSubCategories] = useState([]);
    const [selectedFiles, setSelectedFiles] = useState("");
    const [formData,setFormData]=useState({})
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [gst,setGst]=useState([])
    const [catid, setcatid] = useState(null);
    const [payScreen, setpayscreen] = useState(false);
    const [showForm, setshowForm] = useState(false);
    const [error, setError] = useState(null);
    const [freePlan,setFreePlan]=useState(false);
    const [freeAdd, setFreeAdd]=useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null); 
    const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
    const [selectedOptions, setSelectedOptions] = useState([]); // State to track selected subcategories
    const userId = localStorage.getItem("userId"); // Get user ID from local storage
    const [displayRazorpay, setDisplayRazorpay] = useState(false);
    const token =localStorage.getItem("token"); // Get token from local storage
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');
    const radius = localStorage.getItem('radius');
    const [mapCenter, setMapCenter] = useState({ lat: 17.4065, lng: 78.4772 });
    const handleToggleDropdown = () => {
      setIsOpen((prev) => !prev); // Toggle dropdown visibility
    };
    



    const handleBrowseClick = () => {
        document.getElementById("fileInput").click();
      };

    const handleUpload = async () => {
        console.log(selectedFiles, 'clicked');
        if (selectedFiles.length === 0) {
          toast.warn('No files selected for upload.');
          console.warn('No files selected for upload.');
          return;
        }
      
        const fileUploadPromises = selectedFiles.map(async (file) => {
          let imgformData = new FormData();
          imgformData.append('postedFile', file);
      
          try {
            const response = await axios.post(`${BASE_URL}/Upload`, imgformData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
              },
              onUploadProgress:(ProgressEvent)=>{
                const percentCompleted=Math.round((ProgressEvent.loaded *100)/ProgressEvent.total);
                setUploadProgress(percentCompleted)
              }
            });
            console.log(response.data, "uploaded")
            console.log(`File uploaded: ${file.name}`);
            return response.data.filePath;
          } catch (error) {
            console.error(`Error uploading ${file.name}:`, error);
            throw error;
          }
        });
      
        try {
          // Wait for all files to be uploaded
          const uploadedFilePaths = await Promise.all(fileUploadPromises);
          
          // Join the file paths into a single string separated by commas
          const imageString = uploadedFilePaths.join(',');
      
          setFormData(prevFormData => ({
            ...prevFormData,
            images: imageString // Set the images as a comma-separated string
          }));
      
          toast.success("All images uploaded successfully!");
        } catch (error) {
          toast.error('Error uploading some files.');
          console.error('Error uploading files:', error);
        }
      }; 


    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        // Limit the number of images
        const maxImages = 5; // Set the max number of images you want
        if (files.length + selectedFiles.length > maxImages) {
          toast.warn(`You can only upload a maximum of ${maxImages} images.`);
        } else {
          setSelectedFiles([...selectedFiles, ...files]);
        }
      };
 
        const initialFormData = {
        adTitle:"",
        address:"",
        addtiltle:"",
        advertisements:"",
        barAssociationMember:"",
        categoryId:formData.categoryId,
        categoryName:formData.categoryName,
        categoryTypeIds:formData.categoryTypeIds,
        clickCount:0,
        email:"",
        favouriteCount:0,
        favouriteId:0,
        gst:"",
        images:"",
        invoiceNumber:"",
        jobDescription:"",
        latitude:"",
        longitude:"",
        material:"",
        mobileNumber:"",
        name:"",
        paymentPrice:"",
        positionType:"",
        profession:"",
        qualification:"",
        retailerName:"",
        salaryFrom:"",
        salaryTo:"",
        servicePlanId:0,
        servicePlanName:"",
        servicePlanValidity:"",
        servicePrice:"",
        servicesViewedUsers:"",
        stateBarCouncil:"",
        statusId:"",
        statusName:"",
        totalwithGst:"",
        userId:userId,
        userName:"",
        viewCount:formData.viewCount,
        wanted:"",
        whatyouareselling:"",
        workDescription:""
        }
      
    useEffect(() => {
        fetch(`${BASE_URL}/Services/${servicesId}`, {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        })
          .then((response) => response.json())
          .then((data) => {
            setFormData(data);
            console.log(data,"servicesDetails")
            setshowForm(true); 
      
          })
          .catch((error) => console.error("Error fetching villa details:", error));
      }, [servicesId]);

      const removeFile = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);
      };

    const handleShowForm = (categoryId) => {
        // Show the form when a new category is selected
        setshowForm(true); // Make sure to set this to true to show the form
      };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
    const handleSubmitclick = (e) => {
        e.preventDefault();
      }

    const handleSubmit = async (e) => {
        // e.preventDefault();
        setLoading(true);
        try {
          const formDataToSend = {
            servicesId:servicesId,
            categoryId: formData.categoryId,
            categoryTypeIds: formData.categoryTypeId,
            servicePlanId: formData.servicePlanId,
            paymentPrice: formData.paymentPrice,
            name:String(formData.name),
            retailerName:String(formData.retailerName),
            stateBarCouncil:String(formData.stateBarCouncil),
            images:String(formData.images),
            adTitle:String(formData.adTitle),
            profession:String(formData.profession),
            whatyouareselling:String(formData.whatyouareselling),
            latitude: String(formData.latitude),
            longitude: String(formData.longitude),
            address:String(formData.address),
            salaryFrom:String(formData.salaryFrom),
            salaryTo:String(formData.salaryTo),
            positionType:String(formData.positionType),
            userId:userId,
            jobDescription:String(formData.jobDescription),
            qualification:String(formData.qualification)
    
          };
          const response = await axios.post(`${BASE_URL}/Services/${servicesId}`, formDataToSend,{
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          });
          // setLoading(false);
          console.log(response)
          // const requiredFields = [
          //   "name",
          //   // "serviceProviderName",
          //   "address",
          //   // "paymentPrice",
          //   "images",
          //   // "latitude",
          //   // "longitude"
          // ];
        
          // // Check if all required fields are filled
          // const isFormDataComplete = requiredFields.every(
          //   (field) => formData[field] !== null && formData[field] !== undefined && formData[field] !== ""
          // );
        
          // if (!isFormDataComplete) {
          //   toast.warn("Please fill all required fields.");
          //   setError("Field is required");
          //   setDisplayRazorpay(false);
          //   return; // Stop execution if any required field is missing
          // }
    
          console.log(response.data, "successful");
          toast.success('service saved successfully')
          // setFormData({});
          setSelectedFiles([]);          // Clear selected files
          setSelectedOptions([]);        // Clear selected subcategories
          setcatid(null);                // Reset category ID
          setshowForm(false);            // Hide the form if needed
          setError(null);                // Clear any errors
          // toast.success("Form reset successfully after payment!");
          // handlepayscreen();
        } catch (error) {
          console.error("Error submitting form:", error.response);
          setError(error.message);
          setLoading(false);
        }
      };


    useEffect(() => {
        const fetchData = async () => {
          try {
            const [categoriesResponse, servicePlansResponse] = await Promise.all([
              axios.get(`${BASE_URL}/Categories`),
              axios.get(`${BASE_URL}/ServicePlans`),{
                headers: {
                  Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
                },
              }
            ]);
            setCategories(categoriesResponse.data);
            setServiceplans(servicePlansResponse.data);
          } catch (err) {
            setError(err.message);
          }
        };
        fetchData();
      }, []);


    const handleMapClick = async (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
    
        setFormData((prevData) => ({
          ...prevData,
          latitude: lat,
          longitude: lng,
        }));
    
        // Reverse geocode to get the address
        try {
          const response = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAP_API}`
          );
          if (response.data.results[0]) {
            setFormData((prevData) => ({
              ...prevData,
              address: response.data.results[0].formatted_address,
            }));
          }
        } catch (error) {
          console.error("Error fetching address:", error);
        }
      };
  return (
    <div className="sf-cont">
    <ToastContainer />
    <div className="sf-left-cont">
      <div className="sf-title-sec">
        <div className="sf-title-left">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item c-active">
                <a href="/home">Home</a>
              </li>
              <li className="breadcrumb-item active">
                <a href="/my-services">Service</a>
              </li>
              <li className="breadcrumb-item" aria-current="page">
                Add Service
              </li>
            </ol>
          </nav>
          <p className="third-text">Add Service</p>
        </div>
        <div className="sf-title-right">
        {/* <select
className="form-select"
aria-label="Default select example"
value={catid || ""} 
onChange={(e) => {
  const selectedCategoryId = e.target.value; 
  if (selectedCategoryId !== "") {
    setcatid(selectedCategoryId); 
    setFormData({
      ...formData,
      categoryId: Number(selectedCategoryId),
    });
    handleShowForm(selectedCategoryId);
  }
}}
> */}
{/* <option value="" disabled>Select Category</option> Disabled by default */}

<input
        type="text"
        className={`form-control ${
            formData.categoryName ? "active" : "inactive"
          }`} 
        placeholder="Ad Title *"
        name="categoryName"
        value={formData.categoryName} 
        required
      />


</div>


      </div>   
      {showForm ? (
        <form className="sf-form-input" onSubmit={handleSubmitclick}>
          <div className="sf-2-piece">
                        <div className="lpm-part-6-1">
    <p style={{padding:"0px"}}>Add Photos</p>
    <div className="lpm-part-6-upload-img" style={{padding:"0px"}}>
      <div className="lpm-part-6-border" onClick={handleBrowseClick}>
        <FaRegImage className="lpm-part-6-image-icon" />
        <button type="button">Browse files</button>
      </div>
    </div>
    <input
      id="fileInput"
      type="file"
      multiple
      accept="image/*"
      style={{ display: "none" }}
      onChange={handleFileChange}
    />
    <div className="lpm-part-6-add-pics">
      {((uploadProgress>0)&&(uploadProgress < 100)) &&(
        <div className="upload-progress">
          <p>Uploading:{uploadProgress}%</p>
          </div>
      )}
      <button onClick={handleUpload}>Submit Photos</button>
    </div>

    {/* Display selected images */}
    <div className="selected-files">
        {selectedFiles.length > 0 && (
          <ul>
            {selectedFiles.map((file, index) => (
              <li key={index} style={{position: 'relative', display: 'inline-block', margin: '10px' }}>
                <img
                  src={URL.createObjectURL(file)}
                  alt={`selected ${index}`}
                  loading="lazy"
                  width="100px"
                  height="100px"
                />
                <button
                  onClick={() => removeFile(index)}
                  style={{
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    background: '#ffffffcc',
                    border: 'none',
                    borderRadius:"50%",
                    cursor: 'pointer',
                    color: 'black',
                    fontSize: '12px',
                  }}
                  aria-label="Remove"
                >
                  ✖
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    {error && <p className="error-message" style={{fontSize:"12px" ,fontWeight:"500",color:"red"}}>{error}</p>}
    </div>
  <div className="col">
      <input
        type="text"
        className="form-control"
        placeholder="Ad Title *"
        name="adTitle"
        value={formData.adTitle}
        onChange={handleInputChange}
        required
      />
    </div>
    {error && <p className="error-message" style={{fontSize:"12px" ,fontWeight:"500",color:"red"}}>{error}</p>}
    {catid === "10" && (
      <> <div className="col">
       <input
         type="text"
         className="form-control"
         placeholder="Company Name"
         name="retailerName"
         value={formData.retailerName}
         onChange={handleInputChange}
         required
       />
     </div>
     <div className="col">
       <input
         type="text"
         className="form-control"
         placeholder="Job Role"
         name="profession"
         value={formData.profession}
         onChange={handleInputChange}
         required
       />
     </div>
     <div className="col">
       <input
         type="text"
         className="form-control"
         placeholder="Qualification"
         name="qualification"
         value={formData.qualification}
         onChange={handleInputChange}
         required
       />
     </div>
     </>
    )}

{     catid ==="10" &&
 <div className="salary-content">
         <div className="col">
       <input
         type="text"
         className="form-control"
         placeholder="Salary From"
         name="salaryFrom"
         value={formData.salaryFrom}
         onChange={handleInputChange}
         required
       />
     </div>
     <div className="col">
       <input
         type="text"
         className="form-control"
         placeholder="Salary To"
         name="salaryTo"
         value={formData.salaryTo}
         onChange={handleInputChange}
         required
       />
     </div>
 <div className="lpm-part-4-1">
  <div className="lpm-part-4-1-select">
      <select
        className={`lpm-part-4-1-dropdown ${formData.positionType ? " active" : ""}`}
        name="positionType"
        value={formData.positionType}
        onChange={handleInputChange}
      >
        <option value="" disabled>
        positionType
        </option>
        <option value="Full time">Full time</option>
        <option value="Part time">Part time</option>
        <option value="Temporary">Temporary</option>
        <option value="Contract Basis">Contract Basis</option>


      </select>
      <FaCaretDown className="dropdown-icon" />
    </div>
     </div>
     <div className="col">
       <input
         type="text"
         className="form-control"
         placeholder="Job Description"
         name="jobDescription"
         value={formData.jobDescription}
         onChange={handleInputChange}
         required
       />
     </div>

    </div>
    
    }




{catid!=="10" && <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>}
      {catid !=="10" && <div className="row">
            <div className="mb-3">
              <input
                className="form-control"
                placeholder="work"
                name="profession"
                value={formData.profession}
                onChange={handleInputChange}
                required
              ></input>
            </div>
          </div>}
            
          <div className="map-container">
                <LoadScript googleMapsApiKey={MAP_API}>
                  <GoogleMap
                    mapContainerStyle={{
                      height: "300px",
                      width: "100%",
                      borderRadius: "16px",
                    }}
                    center={mapCenter}
                    zoom={10}
                    onClick={handleMapClick}
                  >
                    {formData.latitude && formData.longitude && (
                      <Marker
                        position={{
                          lat: formData.latitude,
                          lng: formData.longitude,
                        }}
                      />
                    )}
                  </GoogleMap>
                </LoadScript>
              </div> 
    {error && <p className="error-message" style={{fontSize:"12px" ,fontWeight:"500",color:"red"}}>{error}</p>}




     { catid !=="10" && <><div className="row">
            <div className="mb-3">
            <p style={{padding:"16px"}} onChange={handleInputChange}>
                  <strong>Address:</strong> {formData.address}
                </p>
            </div>
          </div>
    {error && <p className="error-message" style={{fontSize:"12px" ,fontWeight:"500",color:"red"}}>{error}</p>}
          </>
          }
          {catid === "10" && 
          <div className="row">
          <div className="mb-3">
          <p style={{padding:"16px"}} onChange={handleInputChange}>
                <strong>Job Location:</strong> {formData.address}
              </p>
          </div>
        </div>
          }

          

{  catid !=="10"  &&  <div className="row">
  <div className="mb-3">
    <textarea
      className="form-control"
      id="exampleFormControlTextarea1"
      rows="4"
      placeholder="what are you selling"
      name="whatyouareselling"
      value={formData.whatyouareselling}
      onChange={handleInputChange}
      required
    ></textarea>
  </div>
</div>}


          </div>

          
          {/* <div className="row">
            <div className="mb-3">
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows="4"
                placeholder="Address"
                name="address"
                value={formData.address}
                onChange={handleInputChange}
                required
              ></textarea>
            </div>
          </div> */}




      {catid === 10 && 
       <div className="col">
       <input
         type="text"
         className="form-control"
         placeholder="Job"
         name="profession"
         value={formData.profession}
         onChange={handleInputChange}
         required
       />
     </div> }   
     <div className="lpm-part-6-final">
      <button
        className="lpm-final-button"
        onClick={() => {
          handleSubmit();
        }}
      >
       Save Changes
      </button>
    </div>


          {success && (
            <p className="success-message">Service added successfully!</p>
          )}
        </form>
        
      ) : (
        // <p
        //   className="thir-text"
        //   style={{

        //   }}
        // >
        //   {/* Please select a category */}
        // </p>


<div className="thir-text">
        <img src={service}/>
        <p>Please select a category</p>
        </div>
      )}
     </div>
    <div className="sf-right-up">
          <div className="sf-right-cont">
            <div className="sf-right-top-cont">
              <div className="sf-right-top-cont-1">
                <img src={partyicon} loading="lazy" alt="" />
                <p className="sf-top-cont-desc">Free Trial For 3 Months</p>
              </div>
              <div className="sf-right-top-cont-2">
                <div className="sf-right-top-cont-2-text">
                  <p className="sf-right-top-cont-2-text1">
                    Post your services and grow your business
                  </p>
                  <p className="sf-right-top-cont-2-text2">
                    Post your services with the lowest prices and get
                    notified.
                  </p>
                </div>
              </div>
            </div>

          </div>
     
    </div>
  </div>
  )
}

export default EditService