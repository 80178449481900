import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "../../Styles/VillaList.css";
import Villa from "../../Components/HomeComponent/Villas";
import image from "../../Assets/FH_image.svg";
import { BASE_URL } from "../../Services/BaseUrl";
import { ToastContainer } from "react-toastify";
import propertyimage from '../../Assets/sellinglady.png'

const PropertyList = () => {

    const navigate = useNavigate();
    const {propertyId}=useParams();
    const location = useLocation();
    const [villas, setVillas] = useState([]);
    const [filteredVillas, setFilteredVillas] = useState([]);
    const [propertytypes, setPropertyTypes] = useState([]);
    const [menuItems, setMenuItems] = useState([]);
    const [showDropdown, setShowDropdown] = useState(false);
    const [locations, setLocation] = useState([]);
    const [sellerTypes, setSellerTypes] = useState([]);
    const [advertisement,setAdvertisement]=useState([]);
    const [sellerTypeId, setSellerTypeId] = useState("all"); // default to "all" to show all properties
    const userId = localStorage.getItem('userId') || 0;
    const token =localStorage.getItem('token');
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');
    const radius = localStorage.getItem('radius');
  
  
    useEffect(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location]);
  
  
  
    const handleAddMoreClick = () => {
      setShowDropdown(!showDropdown);
    };
  
    const encodeUri = (val) => {
      if (val && typeof val === 'string') {
        if (val.startsWith("http")) {
          return val;
        }
        const url = encodeURI(val);
        return `${BASE_URL}/Upload/${url}`;
      }
      console.warn("Provided value is null or undefined, returning empty string.");
      return "";
    };
  
    const handleMenuItemClick = (item) => {
      if (!menuItems.includes(item)) {
        setMenuItems([...menuItems, item]);
        setShowDropdown(false);
      }
    };
  
    const handleRemoveItemClick = (index) => {
      const updatedItems = menuItems.filter((_, i) => i !== index);
      setMenuItems(updatedItems);
    };
  
    const decodeImgeString = (str) => {
      if (str && str.split(",").length > 1) {
        const imageUrl = str.split(",")[0];
        return encodeUri(imageUrl);
      } else if (str) {
        return encodeUri(str);
      }
    };
  
    useEffect(() => {
        const fetchVillas = async () => {
          try {
            const response = await axios.get(`${BASE_URL}/Properties/${propertyId}/propertytypes`, {
              params: {
                userId: userId,
                latitude: latitude,
                longitude: longitude,
                radius: radius,
              },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
      
            const villasData = response.data.vmlist || [];
            const advertisement =response.data.advertisement || [];
            setAdvertisement(advertisement)
            console.log(villasData,"proeprties lsit")
            setVillas(villasData);

            setFilteredVillas(villasData); // Set default filtered villas
      
            // Extract unique property types and locations
            const uniquePropertyTypes = [...new Set(villasData.map((villa) => villa.propertyType))];
            const uniqueLocations = [...new Set(villasData.map((villa) => villa.locationName))];
      
            setPropertyTypes(uniquePropertyTypes);
            setLocation(uniqueLocations);
      
            console.log("Property Types:", uniquePropertyTypes);
            console.log("Locations:", uniqueLocations);
          } catch (error) {
            console.error("Error fetching villas:", error);
          }
        };
      
        fetchVillas();
      }, []);
      
    const fetchSellerTypes = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/SellerTypes`,          {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        });
        setSellerTypes(response.data); // Set fetched data to state
        console.log(response.data, "seller types.................");
      } catch (error) {
        console.error("Error fetching seller types:", error);
      }
    };
  
    useEffect(() => {
      fetchSellerTypes();
    }, []);
  
    const handleSellerTypeChange = (event) => {
        const selectedSellerType = event.target.value;
        setSellerTypeId(selectedSellerType);
      
        // If "all" is selected, reset the filteredVillas to show all properties
        if (selectedSellerType === "all") {
          setFilteredVillas(villas);
        } else {
          // Filter properties based on sellerTypeId
          const filtered = villas.filter((villa) => villa.sellerTypeId === Number(selectedSellerType));
          setFilteredVillas(filtered);
        }
      };
      

  return (
     <div className="villalist-container">
      <div className='villalist-content'>
        <div className="villa-dropdowns">
          {propertytypes.length > 0 && (
            <div className="v-addmore">
              <div className="v-addmore-text">{propertytypes.join(', ')}</div>
            </div>
          )}
          {/* {locations.length > 0 && (
            <div className="v-addmore">
              <div className="v-addmore-text">{locations.join(', ')}</div>
            </div>
          )} */}

          <div className="filter">
            <select
              className="v-addmore"
              onChange={handleSellerTypeChange} // Update sellerTypeId on change
              value={sellerTypeId}
            >
              <option key="all" value="all" className="v-addmore-text">
                All Seller Types
              </option>
              {sellerTypes.map((category) => (
                <option key={category.sellerTypeId} value={category.sellerTypeId} className="v-addmore-text">
                  {category.sellerTypeName}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="v-menu-items">
          {menuItems.map((item, index) => (
            <div key={index} className="selected-menu">
              <span className="selected-menu-item">{item}</span>
              <span
                onClick={() => handleRemoveItemClick(index)}
                className="cross-button"
              >
                X
              </span>
            </div>
          ))}
        </div>
      </div>

      {filteredVillas.length > 0 ? (
  <div className="villa-card-container">
    {filteredVillas.map((villa) => (
      <Villa
        key={villa.propertyId}
        propertyId={villa.propertyId}
        favouriteId={villa.favouriteId}
        title={villa.adTitle}
        location={villa.locationName}
        area={villa.area}
        price={villa.propertyPrice}
        rooms={villa.noOfRooms}
        image={decodeImgeString(villa.images)}
        isInitiallyFavourite={villa.favouriteId > 0}
      />
    ))}
  </div>
) : (
  <div className="sp-body-image">
    <img src={propertyimage} loading="lazy" alt="" className="sp-selling-women" />
    <p>No Properties Available</p>
  </div>
)}


      <div className="fh_image">
        <img src={encodeUri(advertisement.imageUrl)} loading="lazy" alt="Featured Housing" />
      </div>
      <ToastContainer />
    </div>
  )
}

export default PropertyList