import React, { useEffect, useState } from 'react';
import { FaMapMarkerAlt, FaCalendarAlt } from 'react-icons/fa';
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { useParams } from 'react-router-dom';
import Chatpopup from '../../containers/Categories/v-Chat-popup';
import { MAP_API } from '../../Services/GmapAPI'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { ArrowBack } from '@material-ui/icons';
import picimg from '../../Assets/Gallery.svg'
import nofav from '../../Assets/No Fav.png';
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import axios from "axios";
import { BASE_URL } from "../../Services/BaseUrl";
import { useNavigate } from 'react-router-dom';
import '../../Styles/v-Categoriepage.css';
const JobsPage = () => {
    const [jobDetails, setJobDetails] = useState([]);
    console.log(jobDetails, "jobDetails")
    const { jobsId } = useParams();
    const navigate = useNavigate()
    console.log(jobsId, "jobsId");
    const token = localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');
    const radius = localStorage.getItem('radius');
    const [show, setShow] = useState(false);
    const [isChatOpen, setIsChatOpen] = useState(false); 
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: MAP_API, // Corrected to use a string
    });
    const encodeUri = (val) => {
        // Check if val is not null or undefined
        if (val && typeof val === 'string') {
          // Check if the value starts with 'http'
          if (val.startsWith("http")) {
            return val; // Return the URL as is
          }
          const url = encodeURI(val);
          return `${BASE_URL}/Upload/${url}`; // Encode and return
        }
    
        // Handle the case where val is null or undefined
        console.warn("Provided value is null or undefined, returning empty string.");
        return ""; // Or handle it as you see fit
      };
      const decodeImgeString = (str) => {
        if (str) {
          // Split the string by commas to get an array of image paths
          const imageArray = str.split(",");
          // Return the array of encoded image URLs
          return imageArray.map((image) => encodeUri(image.trim()));
        }
        return []; // Return an empty array if the string is null or empty
      };
    
      const handleClose = () => setShow(false);
    
    
    
    
      const handleChatClick = () => {
        setShow(true);
      };
      const fetchJobDetails = async () => {
        console.log("hiii")
        try {
    
          const response = await axios.get(`${BASE_URL}/Jobs/${jobsId}`, {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          });
          console.log(response.data, 'service details');
          setJobDetails(response.data);
        } catch (error) {
          console.error('Error fetching services', error);
        }
      };
      useEffect(() => {
        fetchJobDetails();
      }, [jobsId]);


      const mapCenter = {
        lat: Number(jobDetails.latitude),
        lng: Number(jobDetails.longitude),
      };
    
      const mapContainerStyle = {
        height: '300px', // Set the height as needed
        width: '100%',
        marginBottom: '16px',
      };
      const customMapStyle = [
        {
          featureType: 'all',
          elementType: 'labels.text',
          stylers: [{ color: '#878787' }],
        },
        {
          featureType: 'all',
          elementType: 'labels.text.stroke',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'landscape',
          elementType: 'all',
          stylers: [{ color: '#f9f5ed' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'all',
          stylers: [{ color: '#f5f5f5' }],
        },
        {
          featureType: 'road.highway',
          elementType: 'geometry.stroke',
          stylers: [{ color: '#c9c9c9' }],
        },
        {
          featureType: 'water',
          elementType: 'all',
          stylers: [{ color: '#aee0f4' }],
        },
      ];
    

  return (
    <div className="profile-container" key={jobDetails.jobsId}>
    <div className="profile-left">
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item c-active">
            <a href="/home">Home</a>
          </li>
          <li className="breadcrumb-item c-active">
            <a href='/my-services'>My Services</a>
          </li>
          <li className="breadcrumb-item ">
            <a>{jobDetails.name}</a>
          </li>

        </ol>
      </nav>
      <div className="profile-image-section">
        {(jobDetails.images?.length > 0) ?
          (<div id="carouselExample" className="carousel slide">
            <div className="carousel-inner">
              {decodeImgeString(jobDetails.images).map((image, index) => (
                <div
                  className={`carousel-item ${index === 0 ? "active" : ""}`}
                  key={index}
                >
                  <img
                    src={image}
                    alt={`Villa Image ${index + 1}`}
                    className="profile-image"
                  />
                </div>
              ))}
            </div>
            <button
              className="carousel-control-prev"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="prev"
              style={{ width: "5%" }}
            >
              <span
                className="carousel-control-prev-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next"
              type="button"
              data-bs-target="#carouselExample"
              data-bs-slide="next"
              style={{ width: "5%" }}
            >
              <span
                className="carousel-control-next-icon"
                aria-hidden="true"
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
            <div className="vd-count-pics">
              <img src={picimg} alt="" />
              <p>
                {jobDetails.images ? jobDetails.images.split(',').filter(Boolean).length : 0} Photos
              </p>
            </div>

          </div>) : (
            <div className="no-image-section">
              <img src={nofav} alt='' className="no-image" />
              <p>No Image Available</p>
            </div>
          )}
      </div>
      <div className="personal-details">
        <h2>Job Details</h2>
        <p><strong>Title</strong>{jobDetails.adTitle}</p>
        <p><strong>Job Role</strong> {jobDetails.jobRoleName}</p>
        <p><strong>Company Name</strong> {jobDetails.companyName}</p>
        <p><strong>Qualification</strong> {jobDetails.qualification}</p>
        <p><strong>Position Type</strong> {jobDetails.positionType}</p>
        <p><strong>Salary</strong> {jobDetails.salaryFrom} - {jobDetails.salaryTo}</p>
        <p><strong>Job Location</strong> {jobDetails.jobLocation} - {jobDetails.salaryTo}</p>
        <p><strong>Job Description</strong> {jobDetails.jobDescription}</p>


        <h2>Contact Details</h2>
        <p><strong>Email</strong> {jobDetails.email}</p>
        <p><strong>Address</strong>{jobDetails.address}</p>
      </div>
      <div className="description-section">
        <h2>Description</h2>
        <p>  Lots of potential in this home. In the popular area of Atlanta, this home has 5 bedrooms and 2 full baths. Ready for you to come make it your own. Amazing investor or sweat equity enthusiasts opportunity!Lots of potential in this home. In the popular area of Atlanta, this home has 5 bedrooms and 2 full baths. Ready for you to come make it your own. Amazing investor or sweat equity enthusiasts opportunity!Lots of potential in this home. In the popular area of Atlanta, this home has 5 bedrooms and 2 full baths. Ready for you to come make it your own. Amazing investor or sweat equity enthusiasts opportunity!Lots of potential in this home. In the popular area of Atlanta, this home has 5 bedrooms and 2 full baths. Ready for you to come make it your own. Amazing investor or sweat equity enthusiasts opportunity!</p>
      </div>
    </div>
    <div className="profile-right">
      <div className="ad-details">
        {/* <p><strong>AD ID:</strong> 1234 5678 9876</p> */}
        <p>
          <FaCalendarAlt className="icon" /> {new Date(jobDetails.createdDate).toLocaleDateString()}
        </p>
        <p><FaMapMarkerAlt className="icon" />{jobDetails.jobLocation}</p>

      </div>
      <div style={mapContainerStyle}>
        {isLoaded ? (
          <GoogleMap
            zoom={15}
            center={mapCenter}
            mapContainerStyle={mapContainerStyle}
            options={{ styles: customMapStyle }} // Applying custom styles
          >
            {mapCenter?.lat && mapCenter?.lng && (
              <Marker
                position={{
                  lat: mapCenter.lat,
                  lng: mapCenter.lng
                }}
                title="Location"
              />
            )}
          </GoogleMap>
        ) : (
          <p>Loading Map...</p>
        )}
      </div>
      <div>
        <div className="action-item" onClick={handleChatClick}>
          <IoChatbubbleEllipsesOutline className="icon" />
          <h5>Chat</h5>
        </div>
        <Modal
           size="sm"
           aria-labelledby="contained-modal-title-vcenter"
           centered
         show={show}
         onHide={handleClose} 
         className="popup-style">
          {/* <Modal.Header closeButton>
         
          </Modal.Header> */}
          <Modal.Body>Download the Mobile app to chat </Modal.Body>
        </Modal>
      </div>

    </div>

    {/* Conditionally Render Chat in the middle */}
    {isChatOpen && (
      <div className="chat-overlay">
        <Chatpopup closeChat={() => setIsChatOpen(false)} />
      </div>
    )}
  </div>
  )
}

export default JobsPage