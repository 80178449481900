import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import "../../Styles/v-categories.css";
import noservice from '../../Assets/OBJECTS.svg'
import axios from "axios";
import heart from '../../Assets/heart.svg';
import likeicon from "../../Assets/likeicon.svg";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';

import { BASE_URL } from "../../Services/BaseUrl";
import { FaMapMarkerAlt, FaCalendarAlt } from "react-icons/fa";
import { MdKeyboardArrowRight } from "react-icons/md";

const LegalServices = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [categoryService, setCategoryService] = useState([]);
  const [advertisement, setAdvertisement] = useState("");
  const [subCategories, setSubCategories] = useState([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const { categoryId } = useParams();
  const { catname } = location.state || {};
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const latitude = localStorage.getItem("latitude");
  const longitude = localStorage.getItem("longitude");
  const radius = localStorage.getItem("radius");
  const [showModal, setShowModal] = useState(false);

  const [favouritesState, setFavouritesState] = useState({});
  const [loadingStates, setLoadingStates] = useState({});


  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    console.log("Category Name:", catname);
  }, [catname]);

  const encodeUri = (val) => {
    if (val && typeof val === "string") {
      if (val.startsWith("http")) {
        return val;
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`;
    }
    console.warn("Provided value is null or undefined, returning empty string.");
    return "";
  };

  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };

  const fetchCategoryTypeServices = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Services/${categoryId}/categoryTypeService`,
        {
          params: {
            userId: userId,
            latitude: latitude,
            longitude: longitude,
            radius: radius,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { vmlist = [] } = response.data;   
      
    
      console.log(vmlist, "serrvices");

      // Initialize favourites state for all services
      const initialFavouritesState = {};
      vmlist.forEach((service) => {
        initialFavouritesState[service.servicesId] = service.favouriteId > 0;
      });

      setFavouritesState(initialFavouritesState);
      setCategoryService(vmlist);
    } catch (error) {
      console.error("Error fetching services", error);
    }
  };

  const fetchSubCategories = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Categories/${categoryId}/categoryType`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSubCategories(response.data);
    } catch (error) {
      console.error("Error fetching subcategories", error);
    }
  };

  const fetchAdvertisements = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Advertisements/randomAd`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAdvertisement(response.data);
    } catch (error) {
      console.error("Error fetching advertisements", error);
    }
  };

  useEffect(() => {
    fetchCategoryTypeServices();
    fetchSubCategories();
    fetchAdvertisements();
  }, [categoryId]);

  const handleAddToFavourites = async (serviceId) => {
    console.log(serviceId, "serviceid");
    if (!userId) {
      toast.warn("Please log in to add to favourites.");
      return;
    }

    const favourites = { favouritedBy: userId, servicesId: serviceId };
    try {
      const response = await axios.post(`${BASE_URL}/Favourites`, favourites, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log(response, "add favorites");
      setFavouritesState((prev) => ({
        ...prev,
        [serviceId]: true,
      }));
      fetchCategoryTypeServices();
      toast.success("Added to favourites!");


    } catch (error) {
      console.error("Error adding to favourites:", error);
      toast.error("Failed to add to favourites.");
    }
  };

  const handleRemoveFromFavourites = async (favouriteId) => {
    if (!userId) {
      toast.warn("Please log in to remove from favourites.");
      return;
    }

    try {
      const response = await axios.post(
        `${BASE_URL}/Favourites/${favouriteId}/delete`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200 || response.status === 204) {
        toast.success("Removed from favourites!");
        fetchCategoryTypeServices(); // Refresh the list
      } else {
        throw new Error("Unexpected response status");
      }
    } catch (error) {
      console.error("Error removing from favourites:", error);
      toast.error("Failed to remove from favourites.");
    }
  };


  return (
    <div>
      <ToastContainer />
      <div className="legal-services-layout-container">
        <div className="legal-services-container">
          {categoryService.length > 0 ? (
            <div className="legal-services-grid">
              {categoryService.map((service) => (
                <div key={service.servicesId} className="legal-services-card">
                  <div className="service-image">
                    <img
                      src={decodeImgeString(service.images)}
                      alt={service.servicePlanName}
                      loading="lazy"
                      className="legal-services-image"
                      onClick={() =>
                        navigate(`/categorie/${service.servicesId}`, {
                          state: {
                            categoryId: service.categoryId,
                            catname: service.categoryName,
                          },
                        })
                      }
                    />
                    <div
                      className="card-fav-icon"
                      style={{
                        backdropFilter: "blur(5px)",
                        background: "rgba(0, 45, 58, 0.50)",
                        borderRadius: "6px",
                        height: "30px",
                        width: "30px",
                        zIndex: "1",
                      }}
                    >
                      {favouritesState[service.servicesId] ? (
                        <img
                          src={heart}
                          alt="heart"
                          onClick={() =>
                            handleRemoveFromFavourites(service.favouriteId)
                          }
                          style={{ height: "30px", width: "30px" }}
                        />
                      ) : (
                        <img
                          src={likeicon}
                          alt="like"
                          onClick={() => handleAddToFavourites(service.servicesId)}
                          style={{ height: "30px", width: "30px" }}
                        />
                      )}
                    </div>
                  </div>
                  <div style={{ padding: " 0 20px 10px 20px" }}>
                    <h3>{service.name}</h3>
                    <p>{service.categoryName}</p>
                    <p className="legal-services-icon-text">
                      <FaMapMarkerAlt className="legal-services-icon" />{" "}
                      {service.stateBarCouncil}
                    </p>
                    <p className="legal-services-icon-text">
                      <FaCalendarAlt className="legal-services-icon" />{" "}
                      {new Date(service.createdDate).toLocaleDateString()}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="nodata">
              <img src={noservice} alt="" />
              <p>No Services</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LegalServices;

