import React, { useState, useEffect } from "react";
import "../../Styles/ServiceForm.css";
import { useNavigate } from "react-router-dom";
import partyicon from "../../Assets/party-icon.svg";
import PaymentScreen from "./Paymentscreen";
import { FaRegImage } from "react-icons/fa6";
import { BASE_URL } from "../../Services/BaseUrl";
import { ToastContainer, toast } from "react-toastify";
import { MAP_API } from "../../Services/GmapAPI";
import { FaCaretDown, FaCircleQuestion } from "react-icons/fa6";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import RenderRazorpay from '../../Components/PayModule/PayModule';
import service from '../../Assets/services.png'
import circle from '../../Assets/service-round.png'

const ServiceForm = () => {
  const navigate = useNavigate(); // Hook to navigate between routes
  const [serviceplans, setServiceplans] = useState([]);
  // const [selectedImage, setSelectedImage] = useState(null);
  const [categories, setCategories] = useState([]);
  const [uploadProgress, setUploadProgress] = useState(0)
  const [Subcategories, setSubCategories] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [gst, setGst] = useState([])
  const [catid, setcatid] = useState(null);
  const [payScreen, setpayscreen] = useState(false);
  const [showForm, setshowForm] = useState(false);
  const [error, setError] = useState(null);
  const [freePlan, setFreePlan] = useState(false);
  const [freeAdd, setFreeAdd] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // State to manage dropdown visibility
  const [selectedOptions, setSelectedOptions] = useState([]); // State to track selected subcategories
  const userId = localStorage.getItem("userId"); // Get user ID from local storage
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const token = localStorage.getItem("token"); // Get token from local storage
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');
  const [mapCenter, setMapCenter] = useState({ lat: 17.4065, lng: 78.4772 });
  const handleToggleDropdown = () => {
    setIsOpen((prev) => !prev); // Toggle dropdown visibility
  };


  const handleBrowseClick = () => {
    document.getElementById("fileInput").click();
  };
  const handleUpload = async () => {
    console.log(selectedFiles, 'clicked');
    if (selectedFiles.length === 0) {
      toast.warn('No files selected for upload.');
      console.warn('No files selected for upload.');
      return;
    }

    const fileUploadPromises = selectedFiles.map(async (file) => {
      let imgformData = new FormData();
      imgformData.append('postedFile', file);

      try {
        const response = await axios.post(`${BASE_URL}/Upload`, imgformData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
          onUploadProgress: (ProgressEvent) => {
            const percentCompleted = Math.round((ProgressEvent.loaded * 100) / ProgressEvent.total);
            setUploadProgress(percentCompleted)
          }
        });
        console.log(response.data, "uploaded")
        console.log(`File uploaded: ${file.name}`);
        return response.data.filePath;
      } catch (error) {
        console.error(`Error uploading ${file.name}:`, error);
        throw error;
      }
    });

    try {
      // Wait for all files to be uploaded
      const uploadedFilePaths = await Promise.all(fileUploadPromises);

      // Join the file paths into a single string separated by commas
      const imageString = uploadedFilePaths.join(',');

      setFormData(prevFormData => ({
        ...prevFormData,
        images: imageString // Set the images as a comma-separated string
      }));

      toast.success("All images uploaded successfully!");
    } catch (error) {
      toast.error('Error uploading some files.');
      console.error('Error uploading files:', error);
    }
  };
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    // Limit the number of images
    const maxImages = 5; // Set the max number of images you want
    if (files.length + selectedFiles.length > maxImages) {
      toast.warn(`You can only upload a maximum of ${maxImages} images.`);
    } else {
      setSelectedFiles([...selectedFiles, ...files]);
    }
  };
  const [formData, setFormData] = useState({
    name: "",
    retailerName: "",
    stateBarCouncil: "",
    images: "",
    adTitle: "",
    profession: "",
    whatyouareselling: "",
    latitude: "",
    longitude: "",
    address: "",
    salaryFrom: "",
    salaryTo: "",
    positionType: "",
    qualification: "",
    jobDescription: ""

  });
  const removeFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
  };
  const handleShowForm = (categoryId) => {
    // Show the form when a new category is selected
    setshowForm(true); // Make sure to set this to true to show the form
  };
  const handleServicePlanChange = (price, e) => {
    let num = Number(e.target.value);
    setFormData({ ...formData, servicePlanId: num, paymentPrice: price });
  };
  const handlepayscreen = () => {
    if (formData.categoryId && formData.servicePlanId) {
      setpayscreen(true);
    } else {
      setError("Please select a category and a service plan.");
    }
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmitclick = (e) => {
    e.preventDefault();
  }

  const requiredFields = [
    "images",
    "adTitle",
    "latitude",
    "longitude",
    "address",
  ];

  const validateForm = () => {
    const newErrors = {};

    // Validate required fields
    requiredFields.forEach((field) => {
      const value = formData[field];

      // Check if the field is missing or empty
      if (!value || (typeof value === "string" && value.trim() === "")) {
        newErrors[field] = `${field} is required`;
      }
    });

    // Validate "propertyPlanPrice" separately if needed
    // if (!formData.propertyPlanPrice) {
    //   newErrors.propertyPlanPrice = "Please select a plan.";
    // }

    // Set errors in state
    setErrors(newErrors);

    // If there are validation errors, return false
    if (Object.keys(newErrors).length > 0) {
      setDisplayRazorpay(false); // Ensure Razorpay is disabled on validation failure
      return false;
    }

    // If there are no errors, return true
    return true;
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    setLoading(true);
    try {
      const formDataToSend = {
        categoryId: formData.categoryId,
        categoryTypeIds: String(formData.categoryTypeId),
        servicePlanId: formData.servicePlanId,
        paymentPrice:String( formData.paymentPrice),
        name:String( formData.name),
        retailerName: String(formData.retailerName),
        stateBarCouncil:String(formData.stateBarCouncil),
        images: String(formData.images),
        adTitle:String(formData.adTitle),
        profession: String(formData.profession),
        whatyouareselling: String(formData.whatyouareselling),
        latitude: String(formData.latitude),
        longitude: String(formData.longitude),
        address: String(formData.address),
        salaryFrom: String(formData.salaryFrom),
        salaryTo: String(formData.salaryTo),
        positionType: formData.positionType,
        userId: userId,
        jobDescription: String(formData.jobDescription),
        qualification: String(formData.qualification)

      };
      console.log(formData,"formdata after")
      const response = await axios.post(`${BASE_URL}/Services`, formDataToSend, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      if (!validateForm()) {
        toast.warn("Please fill all required fields.");
        return; // Stop execution if validation fails
      }
      console.log(response.data, "successful");
      toast.success('service posted successfully')
      // setFormData([]);
      setSelectedFiles([]);          // Clear selected files
      setSelectedOptions([]);        // Clear selected subcategories
      setcatid(null);                // Reset category ID
      setshowForm(false);            // Hide the form if needed
      setError(null);                // Clear any errors
      // toast.success("Form reset successfully after payment!");
      // handlepayscreen();
    } catch (error) {
      console.error("Error submitting form:", error.response.request.responseText[0]);
      setError(error.message);
      setLoading(false);
    }
  };


  const createPayment = async () => {
    // e.preventDefault();
    if (userId === undefined || userId === null) {
      toast.error("Please login to proceed with payment.");
    }
    if (!formData.servicePlanId) {
      toast.warn("Please select a plan.");
      setError("Field is required");
      setDisplayRazorpay(false);
      return;
    }
    if (!validateForm()) {
      // setError("Field is required");
      toast.warn("Please fill all required fields.");
      return; // Stop execution if validation fails
    }
    setDisplayRazorpay(true);
    console.log("All validations passed, Razorpay can be displayed");

  }


  const handlePayment = async (status, orderDetails) => {
    console.log(status, "---starting- ", orderDetails);
    console.log(displayRazorpay, 'before success');
    if (status === "succeeded") {
      setDisplayRazorpay(false);  // Hide payment interface
      console.log(displayRazorpay, 'after setting to false (immediate, before state update)');

      // Submit the form
      await handleSubmit(orderDetails);

      // Reset form fields and other states after successful payment
      setFormData([]);
      setSelectedFiles([]);          // Clear selected files
      setSelectedOptions([]);        // Clear selected subcategories
      setcatid(null);                // Reset category ID
      setshowForm(false);            // Hide the form if needed
      setError(null);                // Clear any errors
      toast.success("Form reset successfully after payment!");

    } else if (status === "cancelled") {
      setDisplayRazorpay(false);
    }
  };
  // useEffect(() => {
  //   const fetchSubCategories = async () => {
  //     if (!catid) return; // Prevent fetching if no category selected
  //     try {
  //       setLoading(true);
  //       const subCategoryResponse = await axios.get(
  //         `${BASE_URL}/Categories/${catid}/categoryType`,{
  //           headers: {
  //             Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
  //           },
  //         }
  //       );
  //       setSubCategories(subCategoryResponse.data);
  //       setSelectedOptions([]); // Clear selected subcategories when category changes
  //       setFormData((prevFormData) => ({
  //         ...prevFormData,
  //         categoryTypeId: [], // Clear categoryTypeId in formData
  //       }));
  //     } catch (error) {
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchSubCategories();
  // }, [catid]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [categoriesResponse, servicePlansResponse] = await Promise.all([
          axios.get(`${BASE_URL}/Categories`),
          axios.get(`${BASE_URL}/ServicePlans`), {
            headers: {
              Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
            },
          }
        ]);
        setCategories(categoriesResponse.data);
        setServiceplans(servicePlansResponse.data);
      } catch (err) {
        setError(err.message);
      }
    };
    fetchData();
  }, []);


  const fetchFreeAdd = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/ServicePlans/${userId}/serviceplan`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      const advertisement = response.data;

      // Check if any item in the response has propertyFeeId equal to 0
      const hasFreePlan = advertisement.some(fee => fee.servicePlanId === 0);

      setFreePlan(hasFreePlan);
      setFreeAdd(advertisement);
      console.log(advertisement, 'property free fees response');
    } catch (error) {
      console.error('Error fetching property fees', error);
    }
  };
  const fetchGst = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Gst`, {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setGst(response.data);
      console.log(response.data, "gstt")
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  useEffect(() => {
    fetchGst();
    fetchFreeAdd();
  }, []);


  const handleMapClick = async (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();

    setFormData((prevData) => ({
      ...prevData,
      latitude: lat,
      longitude: lng,
    }));

    // Reverse geocode to get the address
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${MAP_API}`
      );
      if (response.data.results[0]) {
        setFormData((prevData) => ({
          ...prevData,
          address: response.data.results[0].formatted_address,
        }));
      }
    } catch (error) {
      console.error("Error fetching address:", error);
    }
  };



  const calculateTotalWithGST = () => {
    const gstPercentage = gst[0]?.gstName || 0;
    const paymentPrice = formData.paymentPrice || 0;
    const gstAmount = paymentPrice * (gstPercentage / 100);
    const totalamt = Number(paymentPrice) + Number(gstAmount)
    return totalamt * 100;
  };


  const apiKey = 'rzp_test_4rrCmYtqWUOUvT'
  console.log(apiKey, "apiKey: " + apiKey);
  console.log(formData)
  console.log(categories)
  console.log(typeof catid)

  return (
    <div className="sf-cont">
      <ToastContainer />
      <div className="sf-left-cont">
        <div className="sf-title-sec">
          <div className="sf-title-left">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item c-active">
                  <a href="/home">Home</a>
                </li>
                <li className="breadcrumb-item active">
                  <a href="/my-services">Service</a>
                </li>
                <li className="breadcrumb-item" aria-current="page">
                  Add Service
                </li>
              </ol>
            </nav>
            <p className="third-text">Add Service</p>
          </div>
          <div className="sf-title-right">
            <select
              className="form-select"
              aria-label="Default select example"
              value={catid || ""} // Set to empty string initially to show the disabled option
              onChange={(e) => {
                const selectedCategoryId = e.target.value; // Get the selected category ID
                if (selectedCategoryId !== "") { // Make sure we don't trigger form on empty selection
                  setcatid(selectedCategoryId); // Update the selected category ID
                  setFormData({
                    ...formData,
                    categoryId: Number(selectedCategoryId), // Set categoryId in formData
                  });
                  handleShowForm(selectedCategoryId); // Show the form when a category is selected
                }
              }}
            >
              <option value="" disabled>Select Category</option> {/* Disabled by default */}
              {categories
                .filter((category) => category.categoryId !== 1) // Exclude categoryId 1
                .map((category, index) => (
                  <option key={index} value={category.categoryId}>
                    {category.categoryName}
                  </option>
                ))}
            </select>

          </div>


        </div>
        {showForm ? (
          <form className="sf-form-input" onSubmit={handleSubmitclick}>
            <div className="sf-2-piece">
              <div className="lpm-part-6-1">
                <p style={{ padding: "0px" }}>Add Photos</p>
                <div className="lpm-part-6-upload-img" style={{ padding: "0px" }}>
                  <div className="lpm-part-6-border" onClick={handleBrowseClick}>
                    <FaRegImage className="lpm-part-6-image-icon" />
                    <button type="button">Browse files</button>
                  </div>
                </div>
                <input
                  id="fileInput"
                  type="file"
                  multiple
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <div className="lpm-part-6-add-pics">
                  {((uploadProgress > 0) && (uploadProgress < 100)) && (
                    <div className="upload-progress">
                      <p>Uploading:{uploadProgress}%</p>
                    </div>
                  )}
                  <button onClick={handleUpload}>Submit Photos</button>
                </div>

                {/* Display selected images */}
                <div className="selected-files">
                  {selectedFiles.length > 0 && (
                    <ul>
                      {selectedFiles.map((file, index) => (
                        <li key={index} style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`selected ${index}`}
                            loading="lazy"
                            width="100px"
                            height="100px"
                          />
                          <button
                            onClick={() => removeFile(index)}
                            style={{
                              position: 'absolute',
                              top: '0',
                              right: '0',
                              background: '#ffffffcc',
                              border: 'none',
                              borderRadius: "50%",
                              cursor: 'pointer',
                              color: 'black',
                              fontSize: '12px',
                            }}
                            aria-label="Remove"
                          >
                            ✖
                          </button>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
                {errors.images && <p style={{ color: "red" }}>{errors.images}</p>}
                </div>
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Ad Title *"
                  name="adTitle"
                  value={formData.adTitle}
                  onChange={handleInputChange}
                  required
                />
                {errors.adTitle && <p style={{ color: "red" }}>{errors.adTitle}</p>}

              </div>
              {/* {error && <p className="error-message" style={{ fontSize: "12px", fontWeight: "500", color: "red" }}>{error}</p>}
              {catid === "10" && (
                <> <div className="col">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Company Name"
                    name="retailerName"
                    value={formData.retailerName}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Job Role"
                      name="profession"
                      value={formData.profession}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Qualification"
                      name="qualification"
                      value={formData.qualification}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </>
              )} */}

              {catid === "10" &&
                <div className="salary-content">
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Salary From"
                      name="salaryFrom"
                      value={formData.salaryFrom}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Salary To"
                      name="salaryTo"
                      value={formData.salaryTo}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                  <div className="lpm-part-4-1">
                    <div className="lpm-part-4-1-select">
                      <select
                        className={`lpm-part-4-1-dropdown ${formData.positionType ? " active" : ""}`}
                        name="positionType"
                        value={formData.positionType}
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          positionType
                        </option>
                        <option value="Full time">Full time</option>
                        <option value="Part time">Part time</option>
                        <option value="Temporary">Temporary</option>
                        <option value="Contract Basis">Contract Basis</option>


                      </select>
                      <FaCaretDown className="dropdown-icon" />
                    </div>
                  </div>
                  <div className="col">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Job Description"
                      name="jobDescription"
                      value={formData.jobDescription}
                      onChange={handleInputChange}
                      required
                    />
                  </div>

                </div>

              }




              {catid !== "10" && <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
                {errors.name && <p style={{ color: "red" }}>{errors.name}</p>}

              </div>}
              {catid !== "10" && <div className="row">
                <div className="mb-3">
                  <input
                    className="form-control"
                    placeholder="work"
                    name="profession"
                    value={formData.profession}
                    onChange={handleInputChange}
                    required
                  ></input>
                </div>
                {errors.profession && <p style={{ color: "red" }}>{errors.profession}</p>}

              </div>}

              <div className="map-container">
                <LoadScript googleMapsApiKey={MAP_API}>
                  <GoogleMap
                    mapContainerStyle={{
                      height: "300px",
                      width: "100%",
                      borderRadius: "16px",
                    }}
                    center={mapCenter}
                    zoom={10}
                    onClick={handleMapClick}
                  >
                    {formData.latitude && formData.longitude && (
                      <Marker
                        position={{
                          lat: formData.latitude,
                          lng: formData.longitude,
                        }}
                      />
                    )}
                  </GoogleMap>
                </LoadScript>
              </div>
              {errors.latitude && <p style={{ color: "red" }}>{errors.latitude}</p>}
              {errors.longitude && <p style={{ color: "red" }}>{errors.longitude}</p>}
              {catid !== "10" && <><div className="row">
                <div className="mb-3">
                  <p style={{ padding: "16px" }} onChange={handleInputChange}>
                    <strong>Address:</strong> {formData.address}
                  </p>
                </div>
              </div>
              {errors.address && <p style={{ color: "red" }}>{errors.address}</p>}
              </>
              }
              {catid === "10" &&
                <div className="row">
                  <div className="mb-3">
                    <p style={{ padding: "16px" }} onChange={handleInputChange}>
                      <strong>Job Location:</strong> {formData.address}
                    </p>
                  </div>
                </div>
              }



              {catid !== "10" && <div className="row">
                <div className="mb-3">
                  <textarea
                    className="form-control"
                    id="exampleFormControlTextarea1"
                    rows="4"
                    placeholder="what are you selling"
                    name="whatyouareselling"
                    value={formData.whatyouareselling}
                    onChange={handleInputChange}
                    required
                  ></textarea>
                </div>
              </div>}


            </div>


            {/* <div className="row">
              <div className="mb-3">
                <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="4"
                  placeholder="Address"
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  required
                ></textarea>
              </div>
            </div> */}




            {catid === 10 &&
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Job"
                  name="profession"
                  value={formData.profession}
                  onChange={handleInputChange}
                  required
                />
              </div>}

            {freePlan ?
              <div className="lpm-part-6-final">
                <button
                  className="lpm-final-button"
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  3 Months/Free
                </button>
              </div> :
              <div className="sf-right-bottom-cont">
                <button
                  className="btn btn-primary btn-lg btn-block"
                  onClick={() => {
                    createPayment()
                  }}
                >
                  Continue
                </button>
              </div>}


            {success && (
              <p className="success-message">Service added successfully!</p>
            )}
          </form>

        ) : (
          // <p
          //   className="thir-text"
          //   style={{

          //   }}
          // >
          //   {/* Please select a category */}
          // </p>


          <div className="thir-text">
            <img src={service} />
            <p>Please select a category</p>
          </div>
        )}
      </div>
      <div className="sf-right-up">


        <div className="sf-right-cont">
          <div className="sf-right-top-cont">
            <div className="sf-right-top-cont-1">
              <img src={partyicon} loading="lazy" alt="" />
              <p className="sf-top-cont-desc">Free Trial For 3 Months</p>
            </div>
            <div className="sf-right-top-cont-2">
              <div className="sf-right-top-cont-2-text">
                <p className="sf-right-top-cont-2-text1">
                  Post your services and grow your business
                </p>
                <p className="sf-right-top-cont-2-text2">
                  Post your services with the lowest prices and get
                  notified.
                </p>
              </div>
              {freePlan ? null : <div className="sf-right-top-cont-2-bills">
                {serviceplans.map((servicePlan, id) => (
                  <div key={id} className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      value={servicePlan.servicePlanId} // Correctly set the value to servicePlan.id
                      // id={`flexCheckDefault${id + 1}`}
                      name="servicePlan"
                      onChange={(e) =>
                        handleServicePlanChange(servicePlan.servicePrice, e)
                      } // Correctly call the handler
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`flexCheckDefault${id + 1}`}
                    >
                      <span>₹{servicePlan.servicePrice}</span> /Month + GST ({gst[0]?.gstName}%)
                    </label>
                  </div>
                ))}
              </div>}
              {displayRazorpay && (
                <RenderRazorpay
                  amount={calculateTotalWithGST()}
                  currency={"INR"}
                  // orderId={orderDetails.orderId}
                  keyId={apiKey}
                  keySecret={process.env.NEXT_PUBLIC_APP_RAZORPAY_KEY_SECRET}
                  handlePayment={handlePayment}
                  name={localStorage.getItem('username')}
                // name={capitalizeFirstLetter(helper?.loginUserDetail.firstName) + ' ' + capitalizeFirstLetter(helper?.loginUserDetail.lastName)}
                />
              )}

            </div>
          </div>

        </div>

      </div>
    </div>
  );
};

export default ServiceForm;

