import React, { useEffect, useState } from "react";
import axios from "axios";
import ReactDOM from 'react-dom';
import { BASE_URL } from "../../Services/BaseUrl";
import { useNavigate } from "react-router-dom";
import building from '../../Assets/Building.svg'
import smile from '../../Assets/Smile Circle.svg'
import stars from '../../Assets/stars.svg'
import arrowdowm from '../../Assets/arrow-down.svg'
import { BiDownArrow } from "react-icons/bi";
import { toast, ToastContainer } from "react-toastify";

const Home = ({totalProperties,totalUsers}) => {
  const [locations, setLocations] = useState([]);
  const [properties, setProperties] = useState([]);
  const [locationId, setLocationId] = useState("");
  const [propertyTypesId, setPropertyTypesId] = useState("");
  const [keywords, setKeywords] = useState("");
  const [budget, setBudget] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const token=localStorage.getItem('token');
  const userId = localStorage.getItem('userId');
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');

  const fetchData = async (apiUrl, setData) => {
    try {
      const response = await axios.get(apiUrl,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchData(`${BASE_URL}/Locations`, setLocations);
    fetchData(`${BASE_URL}/PropertyTypes`, setProperties);
  }, []);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams();

    // Check for mandatory propertyTypesId
    // if (!propertyTypesId) {
    //   setError("Please select a property type.");
    //   return; // Prevent submission if property type is not selected
    // }

    if (keywords) {
      params.append("keywords", keywords);
    }
    if (locationId) {
      params.append("locationId", locationId);
    }
    if (propertyTypesId) {
      params.append("propertyTypeId", propertyTypesId);
    }
    if (budget) {
      params.append("budget", budget);
    }
    if (params.toString() === "") {
      toast.warn("Please select at least one filter.");
      return; // Prevent submission if no filters are selected
    }
    console.log(params.toString(),"paramshjjgbngj");
    navigate(`/villas-list?${params.toString()}`);
  };

  return (
    <div className="min-vh-100 my-bg-primary my-background-image" style={{display:"flex",flexDirection:"column",gap:"30px",position:"relative",zIndex:"1",marginTop:"-40px"}}>
     {ReactDOM.createPortal(
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          draggable
          pauseOnHover
          pauseOnFocusLoss
          style={{ zIndex:9999 }}
        />,
        document.body // Renders ToastContainer directly inside body
      )}
      <div className="hero-section">
        <div className="hero-content d-flex justify-content-center align-items-center flex-column">
          <h1 className="hero-title">
            Perfect Firm For{" "}
            <span className="highlight fst-italic">Selling</span> Or{" "}
            <span className="highlight fst-italic">Buying</span>
          </h1>
          <div className="text_section border_radius p-3">
            <form className="search-form" onSubmit={onSubmitHandler}>
              <div className="row d-flex justify-content-center justify-content-lg-start">
                <div className="col-md col-12 d-flex justify-content-center">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter Keywords"
                    value={keywords}
                    onChange={(e) => setKeywords(e.target.value)}
                  />
                </div>
                {/* <div className="col-md col-12 d-flex justify-content-center" style={{ position: "relative" }}>
                  <select
                    id="location"
                    className="form-control property_select"
                    value={locationId}
                    onChange={(e) => setLocationId(e.target.value)}
                  >
                    <option value="" disabled>
                      Select your location
                    </option>
                    {locations.map((location) => (
                      <option
                        key={location.locationsId}
                        value={location.locationsId}
                      >
                        {location.locationName}
                      </option>
                    ))}
                  </select>
                  <img src={arrowdowm} loading="lazy" className="downarrow-icon" alt="arrow down" />
                </div> */}
                <div className="col-md col-12 d-flex justify-content-center" style={{ position: "relative" }}>
                  <select
                    id="propertyType"
                    className="form-control property_select custom-arrow"
                    value={propertyTypesId}
                    onChange={(e) => setPropertyTypesId(e.target.value)}
                  >
                    <option value="" disabled>
                      Property Type
                    </option>
                    {properties.map((property) => (
                      <option
                        key={property.propertyTypesId}
                        value={property.propertyTypesId}
                      >
                        {property.propertyType} {/* Displaying property type */}
                      </option>
                    ))}
                  </select>
                  <img src={arrowdowm} loading="lazy" className="custom-arrow-icon" alt="arrow down" />
                </div>
                <div className="col-md col-12 d-flex justify-content-center">
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Budget"
                    value={budget}
                    onChange={(e) => setBudget(e.target.value)}
                  />
                </div>
                <div className="col-md col-12">
                  <button
                    type="submit"
                    className="btn btn-block text-bg-info fw-medium property_search"
                  >
                    Search
                  </button>
                </div>
                {error && (
                  <div className="col-12 text-danger text-center mt-2">
                    {error}
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="d-flex sm-justify-content w-100 mt-4">
            <div className="stats property_icons">
              <span className="text_font"><img src={building} loading="lazy" className="building"/>Over {totalProperties} properties</span>
              <span className="text_font"><img src={smile} loading="lazy" className="building"/>{totalUsers} people's happy</span>
              {/* <span className="text_font">
  <img src={stars}loading="lazy" className="stars" alt="rating stars"/>
  4.8 Top rated by people
</span> */}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
