import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { BASE_URL } from '../../Services/BaseUrl';
import Buildings from '../../Assets/Buildings.svg';
import { useNavigate } from 'react-router-dom';
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Categories = ({ loading }) => {
    const navigate = useNavigate();
    const [categories, setCategories] = useState([]);
    const carouselRef = useRef(null); // Ref for carousel instance
    const token=localStorage.getItem('token');
    const userId = localStorage.getItem('userId');
    const latitude = localStorage.getItem('latitude');
    const longitude = localStorage.getItem('longitude');
    const radius = localStorage.getItem('radius');

    const encodeUri = (val) => {
        if (val && typeof val === 'string') {
            if (val.startsWith("http")) return val;
            return `${BASE_URL}/Upload/${encodeURI(val)}`;
        }
        console.warn("Provided value is null or undefined, returning empty string.");
        return "";
    };

    const fetchCategories = async () => {
        try {
            const response = await axios.get(`${BASE_URL}/Categories`,{
              headers: {
                Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
              },
            });
            setCategories(response.data);
            console.log(response.data,"categoriessss.........");
        } catch (error) {
            console.error('Error fetching categories', error);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 8, // Default for larger screens (max of 9 cards)
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 1440, // Min width: 1440px
            settings: {
              slidesToShow: 8, // Show 8 cards for slightly smaller large screens
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1300, // Min width: 1300px
            settings: {
              slidesToShow: 7, // Show 7 cards
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1162, // Min width: 1162px
            settings: {
              slidesToShow: 6, // Show 6 cards
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 1024, // Min width: 1024px
            settings: {
              slidesToShow: 5, // Show 5 cards
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 900, // Min width: 900px
            settings: {
              slidesToShow: 4, // Show 4 cards for medium screens
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768, // Min width: 768px
            settings: {
              slidesToShow: 4, // Show 3 cards for tablet-sized screens
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 700, // Min width: 700px
            settings: {
              slidesToShow: 3, // Show 3 cards for smaller tablet screens
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 600, // Min width: 600px
            settings: {
              slidesToShow: 3, // Show 2 cards for screens between 600px and 768px
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480, // Min width: 480px
            settings: {
              slidesToShow: 2, // Show 2 cards for smaller mobile screens
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 325, // Min width: 325px
            settings: {
              slidesToShow: 1, // Show 1 card for the smallest screens
              slidesToScroll: 1,
            },
          },
        ],
      };
      
      

    const handlePrevClick = () => {
        if (carouselRef.current) carouselRef.current.prev();
    };

    const handleNextClick = () => {
        if (carouselRef.current) carouselRef.current.next();
    };

    return (
        <div className="categories-container" style={{ position: 'relative' }}>
            <div className="newProperty-title">Categories</div>
            {loading ? (
                <p>Loading...</p>
            ) : (
                <div className='category-container'>
   
     {categories.length > 0 && (
    <div
        className="categories-card"
        onClick={() => navigate(`/category/${categories[0].categoryId}`, { state: { catname: categories[0].categoryName } })}
    >
        <div className="categories-image">
            <img src={encodeUri(categories[0].icon)} loading="lazy" alt={categories[0].categoryName} style={{ width: '50px' }} />
        </div>
        <div className="categories-card-content">
            <span className="categories-title">{categories[0].categoryName}</span>
        </div>
    </div>
)}
     {categories.length > 0 && (
    <div
        className="categories-card"
        onClick={() => navigate(`/categories`)}
    >
        <div className="categories-image">
            <img src={encodeUri(categories[1].icon)} loading="lazy" alt="services" style={{ width: '50px' }} />
        </div>
        <div className="categories-card-content">
            <span className="categories-title">Services</span>
        </div>
    </div>
)}
     {categories.length > 0 && (
    <div
        className="categories-card"
        onClick={() => navigate('/jobs')}
    >
        <div className="categories-image">
            <img src={encodeUri(categories[13].icon)} loading="lazy" alt={categories[13].categoryName} style={{ width: '50px' }} />
        </div>
        <div className="categories-card-content">
            <span className="categories-title">{categories[13].categoryName}</span>
        </div>
    </div>
)}




                    {/* Custom navigation buttons */}
                    {/* <button className="carousel-prev" onClick={handlePrevClick}>❮</button>
                    <button className="carousel-next" onClick={handleNextClick}>❯</button> */}
                </div>
            )}
        </div>
    );
};

export default Categories;
