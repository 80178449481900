import React, { useState, useRef,useEffect} from "react";
import { FaLocationDot, FaCaretDown } from "react-icons/fa6";
import { FaArrowLeft } from "react-icons/fa";
import { FaRegImage } from "react-icons/fa6";
import { PiSealCheckBold } from "react-icons/pi";
import { IoCard } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import paytm from '../../Assets/paytm.png';
import gpay from '../../Assets/gpay.png';
import buildings from "../../Assets/buildings.png";
import smileCircle from "../../Assets/smileCircle.png";
import phonepe from '../../Assets/phonepe.png';
import axios from "axios";
import "../../Styles/PostAd1.css";
import "../../Styles/PostAd2.css";
import ReactDOM from 'react-dom';
import PaymentScreen from "../../Components/services/Paymentscreen";
import { BASE_URL } from "../../Services/BaseUrl";

const PostAd1 = () => {
  const [isPostingAd, setIsPostingAd] = useState(true);
  const [adType, setAdType] = useState("");
  const [uploadProgress, setUploadProgress] = useState(0); // State for upload progress
  const [selectedFile, setSelectedFile] = useState(null);
  const [postContent, setPostContent] = useState("");
  const [freePlan,setFreePlan]=useState(false);
const userId =localStorage.getItem("userId");
const token = localStorage.getItem("token");
const latitude = localStorage.getItem('latitude');
const longitude = localStorage.getItem('longitude');
const radius = localStorage.getItem('radius');
  const handleFileChange = (event) => {
    const postedFile = event.target.files[0]; // Get the first selected file
    if (!postedFile) {
      console.error("No file selected.");
      toast.warn("No file selected")
      return;
    }
    const formData = new FormData();
    formData.append("postedFile", postedFile); // Append the file to FormData

    // Proceed with Axios upload
    axios
      .post(`${BASE_URL}/Upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
           Authorization: `Bearer ${token}`
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted); // Update progress state
        },
      })
      .then((response) => {
        setSelectedFile(response.data?.filePath);
        console.log("File uploaded successfully:", response.data?.filePath);
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
        toast.error("An error occurred while uploading the file. Please try again.");
      });
  };
  const handleNext = async () => {
    if (!userId) {
      toast.warn("Please login to proceed");
      return;
    }
  
    if (adType && postContent && selectedFile && userId) {
      const postData = {
        userId: userId,
        advertisementType: adType,
        title: postContent,
        imageUrl: selectedFile, // Base64 string
        offersId: 0,
        content: postContent,
        locationsId: 0,
        propertyId: 0,
        viewCount: 0,
        clickCount: 0,
        statusId: 0,
        createdDate: new Date().toISOString(),
      };
  
      try {
        const response = await axios.post(
          `${BASE_URL}/Advertisements`,
          postData,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
  console.log(response,"adds posted");
          toast.success("Ad posted successfully!");
          // Reset fields
          setPostContent("");
          setSelectedFile(null);
          setAdType("");
      } catch (error) {
        if (error.response) {
          // Handle specific response errors
          const { status, data } = error.response;
  
          if (status === 404) {
            toast.error(data || "Resource not found.");
          } else if (status === 400) {
            toast.warn(data || "Bad request. Please check your input.");
          } else {
            toast.error(data || "An unexpected error occurred.");
          }
        } else if (error.request) {
          // Handle network or request errors
          toast.error("No response received from the server. Please try again.");
        } else {
          // Handle other errors
          toast.error("An error occurred. Please try again.");
        }
  
        console.error("Error posting ad:", error);
      }
    } else {
      toast.warn("Please fill in all fields before proceeding.");
    }
  };
  

  const handleBack = () => {
    setIsPostingAd(true); // Switch to the PostAd1 form
  };

  const fetchFreeAddplans=async()=>{
    try{
       const response=await axios.get(`${BASE_URL}/Packages/${userId}/postingads`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
       });
       const plans=response.data;
       console.log(plans,"dvbjki8y");
       const hasFreePlan=plans.some(free=>free.packagesId ===0);
       setFreePlan(hasFreePlan);
       console.log(hasFreePlan,"hasFreePlan");     
    }catch(error){
      
    }
  }
  useEffect(()=>{
    fetchFreeAddplans();
  },[]);



  return (
    <div className="lp-container" style={{display:"flex",flexDirection:"column",gap:"30px",position:"relative",zIndex:"1",marginTop:"-130px"}}>
{ReactDOM.createPortal(
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          draggable
          pauseOnHover
          pauseOnFocusLoss
          style={{ zIndex: 99999 }}
        />,
        document.body // Renders ToastContainer directly inside body
      )}
           
      <div className="lp-background2">
      <div className="pa-left-content">
          <h1>
            Post your advertisement to <span>Sell</span> online
          </h1>
          <div className="lp-options">
            <button>
              <img src={buildings} alt="buildings" loading="lazy" className="lp-img-3" />
              Get Noticed with Priority Listings
            </button>
            <button>
              <img src={buildings} alt="buildings" loading="lazy" className="lp-img-3" />
              Sell Faster with Premium Service
            </button>
            <button>
              <img src={smileCircle} alt="smileCircle" loading="lazy" className="lp-img-3" />
              Connect with Verified Buyers 
            </button>
          </div>
        </div>

          <div className="pa-form">
            <div className="pa-form-1">
              <div className="pa-form-ready">
                <div className="pa-form-p">Post Your Ad.</div>
              </div>
            </div>
            <div className="pa-form-2">
              <div className="pa-form-2-1">
                <div className="pa-form-2-1-label">Type of advertisement</div>
                <div className="pa-form-combine">
                  <div className="pa-form-options">
                    <input
                      type="radio"
                      name="adType"
                      id="horizontal"
                      checked={adType === "horizontal"}
                      onChange={() => setAdType("horizontal")}
                    />
                    <label htmlFor="horizontal">Horizontal</label>
                  </div>
                  <div className="pa-form-options">
                    <input
                      type="radio"
                      name="adType"
                      id="vertical"
                      checked={adType === "vertical"}
                      onChange={() => setAdType("vertical")}
                    />
                    <label htmlFor="vertical">Vertical</label>
                  </div>
                </div>
              </div>

              <div className="pa-form-2-2">
                <p>Write about your post</p>
                <textarea
                  placeholder="Write Something"
                  value={postContent}
                  onChange={(e) => setPostContent(e.target.value)}
                />
              </div>
              <div className="pa-form-2-3">
                <div className="pa-form-2-3-1">
                  <p>Upload your banner image</p>
                  <p className="pa-form-2-3-1-1">(524*125 px)</p>
                </div>
                <label
                  className="pa-image-button"
                  style={{ cursor: "pointer" }}
                >
                  <FaRegImage
                    style={{ color: "#00dbbf", width: "25px", height: "25px" }}
                  />
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange} // Call onChange to handle file selection
                    style={{ display: "none" }} // Hide the input
                  />
                  
                </label>
                {((uploadProgress > 0) && (uploadProgress < 100)) && (
            <div className="upload-progress">
              <p>Uploading: {uploadProgress}%</p>
            </div>
          )}
                {selectedFile && (
                  <p className="uploaded-file-name" >
                    File uploaded successfully
                  </p>
                )}
              </div>

              <div className="pa-form-2-4">
                <input type="checkbox" id="exclusiveAd" />
                <label htmlFor="exclusiveAd" style={{ fontSize: "14px" }}>
                  I am Posting this ad exclusively on property adda
                </label>
              </div>

            {freePlan?<><button className="pa-post-button" onClick={handleNext}>
             3 Months/Free
              </button>
              </>
              :  <button className="pa-post-button" onClick={handleNext}>
                Post Now
              </button>}
       
            </div>
          </div>
        
      </div>
    </div>
  );
};

export default PostAd1;
