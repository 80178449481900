import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PromotionBanner from "../chat/PromotionBanner";
import { Modal, Button } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import "../../Styles/Service.css";
import { BASE_URL } from "../../Services/BaseUrl";
import servicedp from "../../Assets/Service-dp.svg";
import eye from "../../Assets/eye icon.svg";
import heart from "../../Assets/heart.svg";
import sellinglady from '../../Assets/sellinglady.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Edit} from "@mui/icons-material";
import { BiEdit } from "react-icons/bi";


const ServiceList = () => {
  const [services, setServices] = useState([]); // State to store services
  const [advertisement, setAdvertisement] = useState([]); // State to store
  const [loading, setLoading] = useState(true); // Set loading to true initially
  const [error, setError] = useState(null); // Error state
  const [popupVisible, setPopupVisible] = useState(null); // State to handle popup visibility
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("token");
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');
  const {servicesId}=useParams();
  const [showPopup, setShowPopup] = useState(false);
  const [currentAd, setCurrentAd] = useState(null);

  const handleShow = (ad) => {
    setCurrentAd(ad);
    setShowPopup(true);
  };
  
  const handleCloseAdd = () => {
    setShowPopup(false);
    setCurrentAd(null);
  };
  
  const encodeUri = (val) => {
    if (val && typeof val === "string") {
      if (val.startsWith("http")) {
        return val;
      }
      return `${BASE_URL}/Upload/${encodeURI(val)}`;
    }
    return "";
  };

  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };

  const fetchServices = async () => {
    console.log('Fetching services')
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}/Services/${userId}/userServices`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      if (response.status !== 204) {
        setServices(response.data.vmlist);
        console.log(response.data.vmlist ,"hgdfvjhefioug....................")
        setAdvertisement(response.data.ad);
      } else {
        setServices([]);
        setAdvertisement([]);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
  }, [userId]);

  const handleAddService = () => {
    navigate("/service-form");
  };

  const handlePopupToggle = (index) => {
    setPopupVisible(popupVisible === index ? null : index); // Toggle popup visibility
  };

  const handleDeleteService = async (servicesId) => {
    console.log(servicesId,"servicesId response");
    try {
      const response = await axios.post(`${BASE_URL}/Services/${servicesId}/delete`,{},{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      console.log(response, 'del res');
      toast.success('Service deleted successfully');
      setTimeout(()=>{
        fetchServices();
      },
    300)
     
      // Refetch the services list after deletion
      // fetchServices();
    } catch (err) {
      console.error(err);
      toast.error('Failed to delete service');
    }
  };

  if (loading) {
    return <div>Loading services...</div>;
  }
  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="serv-cont">
      <ToastContainer />
      <div className="serv-left-cont">
        <div className="serv-breadcrumb-line">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/home">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                <a href="/my-services">Service</a>
              </li>
            </ol>
          </nav>
          <button className="btn btn-primary" onClick={handleAddService}>
            Add Service
          </button>
        </div>
        <div>
          {services && services.length > 0 ? (
            <div className="serv-cards">
              {services.map((service, index) => (
                <div key={index} className="serv-card-full-cont">
                  <div className="serv-card" >
                    <img src={decodeImgeString(service.images)} loading="lazy" alt={service.title}onClick={() => navigate(`/my-services/${service.servicesId}`)} />
                    <div className="serv-details">
                      <div className="serv-title">
                        <p className="primary-text-dark">{service.name}</p>
                        <div className="serviceedit-icon">
                          <div className="edit-icon1">
                        <BiEdit onClick={()=>navigate(`/service-form/${service.servicesId}`)} className="edit-icon"/>
                          </div>
                        <div style={{ position: 'relative', display: 'inline-block' }}>
                          <BsThreeDotsVertical
                            className="three-dots"
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent triggering the card click
                              handlePopupToggle(index);
                            }}
                          />
                          {popupVisible === index && (
                            <div className="popup-menu">
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeleteService(service.servicesId);
                                  setPopupVisible(null);
                                }}
                              >
                            Delete
                              </button>
                            </div>
                            
                          )}
                        </div>
                        </div>
                      </div>
                      <p className="secondary-text" style={{ color: "#00DBBF" }}>
                        {service.categoryName}
                      </p>
                      <div className="serv-stats">
                        <div>
                          <img src={eye} loading="lazy" alt="Views" />
                          {service.viewCount}
                        </div>
                        <div>
                          <img src={heart} loading="lazy" alt="Likes" />
                          {service.favouriteCount}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <img src={sellinglady} loading="lazy" alt="" style={{ height: "35%", width: "45%" }} />
            </div>
          )}
        </div>
      </div>
      <div>
        {advertisement ? (
          <>
       
       <div className="serv-right-cont">
       {advertisement.map((add, index) => (
         <div
           key={index}
           className="advertisement"
           onClick={() => handleShow(add)}
         >
           <img
             src={encodeUri(add.imageUrl)}
             loading="lazy"
             alt="Advertisement"
             className="advertisement"
           />
         </div>
       ))}
     </div>
     <Modal show={showPopup} onHide={handleCloseAdd} size="sm" centered>
     <Modal.Header closeButton>
       <Modal.Title>Image Preview</Modal.Title>
     </Modal.Header>
     <Modal.Body>
       {currentAd && (
         <>
           <img
             src={encodeUri(currentAd.imageUrl)}
             alt=""
             className="img-fluid" // Bootstrap class for responsive image
             style={{ width: "100%", height: "auto" }}
           />
           <br />
           <p style={{ color: "Black" }}>{currentAd.title}</p>
         </>
       )}
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={handleCloseAdd}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>
   </>
     
        ) : (
          <p>empty</p>
        )}
      </div>
    </div>
  );
};

export default ServiceList;
