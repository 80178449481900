import React, { useEffect, useState } from "react";
import "../../Styles/Mylisting3.css";
import arrowimg from "../../Assets/mlleft.png";
import altarrow from "../../Assets/mlaltright.png";
import House1 from "../../Assets/House1.png";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL } from "../../Services/BaseUrl";
import mllocation from "../../Assets/fhlocation.png";
import mlarea3 from "../../Assets/mlarea3.png";
import mlphonepay from "../../Assets/mlphnpay.png";
import mlgpay from "../../Assets/mlgpay.png";
import mlpaytm from "../../Assets/mlpaytm.png";
import mlupitick from "../../Assets/mlupitick.png";
import { useNavigate, useParams } from "react-router-dom";
import RenderRazorpay from '../../Components/PayModule/PayModule';

const Mylisting3 = () => {
  const { id } = useParams();
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [totalAmount, setTotalAmount] = useState(0);
  const [buyPlans, setBuyPlans] = useState([]);
  const [packagesTypes, setPackagesTypes] = useState([]);
  const [listingData, setListingData] = useState({});
  const [gst,setGst]=useState([])
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [existingPackages, setExistingPackages] = useState([]);
  const userId = localStorage.getItem('userId');
  const token = localStorage.getItem('token');
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');
  const navigate = useNavigate();

  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };
  const encodeUri = (val) => {
    if (val && typeof val === "string") {
      if (val.startsWith("http")) {
        return val;
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`;
    }
    console.warn("Provided value is null or undefined, returning empty string.");
    return "";
  };


  const handleCheckboxChange = (cardIndex, option) => {
    if (selectedItem === option) {
      setSelectedItem(null);
      setSelectedCardIndex(null);
      setTotalAmount(0);
    } else {
      setSelectedItem(option);
      setSelectedCardIndex(cardIndex);
      setTotalAmount(option.bumpPostPlanPrice);
  
    }
  };

  const fetchPackageTypes = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/PackageTypes`,   {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setPackagesTypes(response.data);
      response.data.forEach((packageType) => {
        fetchPackages(packageType.packageTypeId);
        console.log(packageType.packageTypeId,"packagetypeid")
      });
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  const fetchPackages = async (packageTypeId) => {
    try {
      const response = await axios.get(
        `${BASE_URL}/BumpPostPlans/${packageTypeId}/packagetypes`,          {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );
      setBuyPlans((prevData) => ({
        ...prevData,
        [packageTypeId]: response.data,
      }));
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const fetchExistingPackages = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/BumpPostProperties/${id}/existedpackage`,          {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setExistingPackages(response.data);
    } catch (error) {
      if (error.status === 400) {
        console.log("Error:", error.request.responseText);
      }
      console.error("Error fetching existing packages:", error);
    }
  };

  useEffect(() => {
    fetchPackageTypes();
    fetchExistingPackages();
  }, []);


  useEffect(() => {
    const fetchListingData = async () => {
      console.log("entered function");
      try {
        console.log("entered try block");
        const response = await axios.get(`${BASE_URL}/Properties/${id}`,          {
          params: {
            userId: userId,
          },
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        });
        console.log("executed API");
        console.log(response);
        setListingData(response.data.vm);
        // setAdvertis(response.data.advertisements);
      } catch (error) {
        console.error("Error fetching listing data:", error);
        // setError("Failed to load listing data. Please try again later.");
      }
    };

    fetchListingData();
  }, [id]);

  const createPayment = () => {
    if (!userId) {
      toast.error("Please login to proceed with payment.");
      return;
    }
if(!selectedCardIndex){
  toast.warn("Please select a plan to proceed");
  return;
}
    if (existingPackages?.bumpPostPlanValidity) {
      return toast.warn("There is an active BumpPostPlan for this property. Please wait until the plan expires.");
    } else if (existingPackages.length === 0) {
      setDisplayRazorpay(true);
    }
  };

  const handlePayment = async (status, orderDetails) => {
    if (status === "succeeded") {
      setDisplayRazorpay(false);
      await handleSubmitPackages(orderDetails);
      await fetchExistingPackages();  // Refresh existing packages after successful payment
    } else if (status === "cancelled") {
      setDisplayRazorpay(false);
    }
  };

  const handleSubmitPackages = async () => {
    if (!selectedItem) {
      toast.warn("Please select a package before proceeding.");
      return;
    }

    const postbumper = {
      paymentPrice: selectedItem.bumpPostPlanPrice,
      propertyId: id,
      bumpPostPlanId: selectedItem.bumpPostPlanId,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}/BumpPostProperties`,
        postbumper,          {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );
      toast.success("Payment successful");
      setSelectedItem(null);
      setSelectedCardIndex(null);
      setTotalAmount(0);
        } catch (error) {
      if (error.status === 400) {
        toast.warn(error.request.responseText);
      } else {
        console.error("Error submitting payment:", error);
      }
    }
  };



  const fetchGst = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Gst`,{
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setGst(response.data);
      console.log(response.data,"gstt")
    } catch (error) {
      console.error("Error fetching package types:", error);
    }
  };

  useEffect(() => {
    fetchGst();
  }, []);


  const calculateTotalWithGST = () => {
    const gstPercentage = gst[0]?.gstName || 0; 
    const paymentPrice = totalAmount || 0; 
    const gstAmount = paymentPrice *( gstPercentage / 100); 
    const totalamt = Number(paymentPrice) + Number(gstAmount)

    return totalamt*100 ; 
  };

  console.log(packagesTypes,'package types above return')
  console.log(buyPlans, "above return");
  console.log(selectedItem,'selected item above return')

  const apiKey = 'rzp_test_4rrCmYtqWUOUvT'
  console.log(apiKey, "apiKey: " + apiKey);

  return (
    <div className="ml3">
      <ToastContainer />
      <div className="ml3-row">
        <div className="ml3-row1" onClick={() => navigate(`/Mylisting1`)}>
          <img src={arrowimg} loading="lazy" alt="arrow" />
          <p>Bump Post</p>
        </div>
        <div className="ml3-card-container">
          {packagesTypes.map((card, cardIndex) => (
            <div className="ml3-card" key={card.packageTypeId}>
              <div className="ml3-card-header">
                <p className="ml3-first">{card.packageTypeName}</p>
                <p>{card.description}</p>
              </div>
              <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignItems:"center",padding:"16px 0"}}>
              {buyPlans[card.packageTypeId]?.length > 0 && (
                <>
                  {/* Accessing packageName and description for the specific packageTypeId */}
                  <p className="ml3-head">
                    {buyPlans[card.packageTypeId][0].bumpPostPlanName}
                  </p>
                  <div className="ml3-arrow">
                    <img src={altarrow} loading="lazy " alt="arrow" />
                    <p className="ml3-boost-info">
                      {buyPlans[card.packageTypeId][0].description}
                    </p>
                  </div>
                </>
              )}
              <div className="ml3-options">
                {buyPlans[card.packageTypeId]?.map((option) => (
                  <div className="ml3-option" key={option.bumpPostPlanId} onClick={() => handleCheckboxChange(cardIndex, option)}
>
                    <input
                      type="checkbox"
                      id={option.bumpPostPlanId}
                      checked={
                        selectedItem?.bumpPostPlanId === option.bumpPostPlanId &&
                        selectedCardIndex === cardIndex
                      }
                      onChange={() => handleCheckboxChange(cardIndex, option)}
                    />
                    <label className="ml3optdayprice">
                      ₹{option.bumpPostPlanPrice} /{" "}
                      {option.bumpPostPlanValidity}
                    </label>
                  </div>
                ))}
              </div>
              {buyPlans[card.packageTypeId]?.length >4 && <p style={{float:"right",color:"#00DBBF",marginTop:"10px",marginBottom:0}}>scroll {`>>`}</p>}
              </div>
            </div>
          ))}
        </div>
      </div>
      {displayRazorpay && (
        <RenderRazorpay
          amount={calculateTotalWithGST()}
          currency={"INR"}
          // orderId={orderDetails.orderId}
          keyId={apiKey}
          keySecret={process.env.NEXT_PUBLIC_APP_RAZORPAY_KEY_SECRET}
          handlePayment={handlePayment}
          existingPackages={existingPackages}
          name={localStorage.getItem('username')}
        // name={capitalizeFirstLetter(helper?.loginUserDetail.firstName) + ' ' + capitalizeFirstLetter(helper?.loginUserDetail.lastName)}
        />
      )}

      {/* Property Details */}
      <div className="ml3propdetails">
        <div className="ml3card1">
          <div className="ml3propdeta">Property Details</div>
          <div className="mlp31">
            <div className="mlp3house1">
            <img src={listingData.images ? decodeImgeString(listingData.images) : House1} alt=""  loading="lazy" className="mlp3houseimg" />
            </div>
            <div className="ml3locarea">
             {listingData.adTitle && <div className="ml3mhankura">{listingData.adTitle}</div>}
              <div className="ml3loc">
                <img src={mllocation} loading="lazy" alt="" />
                <div className="mlmhaloc">{listingData.locationName}</div>
              </div>
              <div className="ml3loc">
                <img src={mlarea3} loading="lazy" alt="" />
                <div className="mlmhaloc">{listingData.noOfRooms}BHK -{listingData.area}sq</div>
              </div>
            </div>
          </div>
        </div>

        {/* Payment Method Section */}
        <div className="mlp32">
          <div className="ml3-payment-method-container">
            <div className="ml3-payment-option">
              <div className="mlpricedet3">
                <div className="mlpdhead3">Price Details</div>
                <div className="ml32pd1">
                  <p>Total</p>
                  <p>{totalAmount}</p>
                </div>             
                <div className="ml32pd3ot">
                  <p className="ml3-ordertotal">GST</p>
                  <p>({gst[0]?.gstName}%)</p>
                </div>
                {/* <div className="ml32pd3ot">
                  <p className="ml3-ordertotal">Grand Total</p>
                  <p>{grandTotal}</p>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        <button className="ml3-proceedbtn" onClick={() => {
              createPayment()
            }}>
          Proceed
        </button>
      </div>
    </div>
  );
};

export default Mylisting3;
{
  /* <p className="ml3-head">{card.heading}</p>
              <div className="ml3-arrow">
                <img src={altarrow} alt="arrow" />
                <p className="ml3-boost-info">{card.boostInfo}</p>
              </div> */
}
