import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../Styles/Mylisting1.css";
import House1 from "../../Assets/House1.png";
import sellingwomen from "../../Assets/sellinglady.png";
import { ArrowBack, Edit } from '@mui/icons-material';
import fhlocation from "../../Assets/fhlocation.png";
import scale from "../../Assets/mlarea.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "axios";
import { BASE_URL } from "../../Services/BaseUrl";

const Mylisting1 = () => {
  const [listings, setListings] = useState([]);
  const [isSold, setIsSold] = useState(false);
  const [filter, setFilter] = useState("All Listings"); // Add filter state
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [advertisement, setAdvertisement] = useState("");
  const token=localStorage.getItem("token");
  const latitude = localStorage.getItem('latitude');
  const longitude = localStorage.getItem('longitude');
  const radius = localStorage.getItem('radius');

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/Properties/${userId}/userProperties`,          {
          params: {
            userId: userId,
          },
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );
      const updatedListings = response.data.map(listing => ({
        ...listing,
        isSold: listing.isPropertySold || false,
      }));
      setListings(updatedListings);
      console.log(listings);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchAdvertisements();
  }, []);

  const updateProperty = (propertyId) => {
    PropertySoldUpdate(propertyId);
  };
  
  const PropertySoldUpdate = async (propertyId) => {
    try {
      await axios.post(
        `${BASE_URL}/Properties/${propertyId}/propertySoldUpdate?isPropertySold=true`,          {
          headers: {
            Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
          },
        }
      );
      toast.success("Property sold successfully");
  
      // Update the listings to reflect the change in both isPropertySold and isSold properties
      setListings((prevListings) =>
        prevListings.map((listing) =>
          listing.propertyId === propertyId
            ? { ...listing, isSold: true, isPropertySold: true }
            : listing
        )
      ); 
      console.log("Property sold updated successfully");
    } catch (error) {
      console.error("Error updating property sold status:", error);
    }
  };
  
  

  const PropertyDelete = async (propertyId) => {
    try {
      await axios.post(`${BASE_URL}/Properties/${propertyId}/delete`,   {},       {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setListings((prevListings) =>
        prevListings.filter((listing) => listing.propertyId !== propertyId)
      );
      toast.success("Property deleted successfully");
      fetchData();
    } catch (error) {
      console.error("Error deleting property:", error);
    }
  };

  const encodeUri = (val) => {
    if (val && typeof val === "string") {
      if (val.startsWith("http")) {
        return val;
      }
      const url = encodeURI(val);
      return `${BASE_URL}/Upload/${url}`;
    }
    console.warn("Provided value is null or undefined, returning empty string.");
    return "";
  };

  const fetchAdvertisements = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/Advertisements/randomAd`,          {
        headers: {
          Authorization: `Bearer ${token}`, // Pass the token in the Authorization header
        },
      });
      setAdvertisement(response.data);
      console.log(response.data, 'ad response');
    } catch (error) {
      console.error('Error fetching advertisements', error);
    }
  };

  const handleNavigationClick = (id) => {
    navigate(`/Mylisting2/${id}`);
  };

  // Filter listings based on the selected filter
  const filteredListings = listings.filter((listing) => {
    if (filter === "Sold") {
      return listing.isSold;
    } else if (filter === "Active") {
      return !listing.isSold;
    }
    return true;
  });
  console.log(listings, "Updated listings");
  const decodeImgeString = (str) => {
    if (str && str.split(",").length > 1) {
      const imageUrl = str.split(",")[0];
      return encodeUri(imageUrl);
    } else if (str) {
      return encodeUri(str);
    }
  };
  
  return (
    <div className="ml1-app-container">
      <ToastContainer />
      <div className="ml1-content-container">
        <div className="listings">
          <div className="mlhead">
            <div style={{cursor:"pointer",display:"flex",flexDirection:"row",gap:"20px",alignItems:"center"   }} onClick={() => {navigate('/')}}>
              <ArrowBack/>
              <p>My Listings</p></div>
            <div className="filter">
              <select
                className="select-box"
                value={filter}
                onChange={(e) => setFilter(e.target.value)} // Update filter on change
              >
                <option>All Listings</option>
                <option>Active</option>
                <option>Sold</option>
              </select>
            </div>
          </div>
          <div className="ml1-listings-container">
            {filteredListings.length > 0 ? (
              filteredListings.map((listing) => (
                <div className="ml1-listing-item" key={listing.propertyId}>
                  <img
                    src={listing.images ? decodeImgeString(listing.images) : House1}
                    loading="lazy"
                    alt={listing.propertyName}
                    className="ml1-listing-image"
                    onClick={() => handleNavigationClick(listing.propertyId)}
                  />
                  <div className="ml1-listing-info">
                    <div className="ml1-titleprice">
                      <h3>{listing.adTitle}</h3>
                      <div className="ml1-left">
                      <p className="ml1-price">Price: {listing.propertyPrice}</p>
                      <Edit
  onClick={() =>
    navigate(`/selling-property/${listing.propertyId}`)
  }
/>

                      </div>
                    </div>

                    <div className="ml1-locscadesact">
                      <div
                        className="ml1-locationscaledes"
                        onClick={() => handleNavigationClick(listing.propertyId)}
                      >
                        <div className="ml1-locationscale">
                          <div className="ml1-fhlocation">
                          <img src={scale} loading="lazy" alt="" className="ml1-locsca" />
                          <p>{listing.area}</p>
                            {/* <img src={fhlocation} loading="lazy" alt="" className="ml1-locsca" /> */}
                            {/* <p>{listing.locationName}</p> */}
                          </div>
                          {/* <div className="ml1-fhscale">
                            <img src={scale} loading="lazy" alt="" className="ml1-locsca" />
                            <p>{listing.area}</p>
                          </div> */}
                        </div>
                        <p className="ml1-description">{listing.description}</p>
                      </div>

                      <div className="ml1-actions">
                        <button
                          className="ml1-mark-sold-btn"
                          onClick={!listing.isPropertySold ? () => updateProperty(listing.propertyId) : null}
                        >
                          {listing.isPropertySold ? 'Sold' : 'Mark as Sold'}
                        </button>
                        <button
                          className="ml1-bump-btn"
                          onClick={() => navigate(`/bumppost/${listing.propertyId}`)}
                        >
                          Bump Post
                        </button>
                        <button className="ml1-delete-btn" onClick={() => PropertyDelete(listing.propertyId)}>Delete Post</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
<div className="sp-body-image">
              <img src={sellingwomen} loading="lazy" alt="" className="sp-selling-women" />
              <p>No listings Available  </p>
            </div>            )}
          </div>
        </div>

        {/* Advertisement */}
        {advertisement.length>0 &&  (
  <div className="mylisting-add" key={advertisement.advertisementId}>
    <img src={encodeUri(advertisement.imageUrl)} loading="lazy" alt="" className="advertisement" />
  </div>
)}

      </div>
    </div>
  );
};

export default Mylisting1;
